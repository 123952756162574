import makeStyles from "@mui/styles/makeStyles";

// material UI styles
export const useStyles = makeStyles((theme) => ({
    root: {
        margin: 20,
        textAlign: "center",
    },
    Header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: "14px",
        borderBottom: "1px solid #00000012",
        position: "absolute",
        width: "100%",
        top: "0",
        padding: "15px 24px 20px",
        backgroundColor: theme.palette.primary.contrastText,
        zIndex: "1",
    },
    modal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "40%",
        boxShadow: 24,
        p: 4,
        backgroundColor: theme.palette.primary.contrastText,
        [theme.breakpoints.down("sm")]: {
            width: "90%",
        },
        [theme.breakpoints.down("md")]: {
            width: "90%",
        },
    },
    modalTittlebtn: {
        backgroundColor: "#0f2b79 !important",
        marginLeft: "10px",
        textTransform: "capitalize",
        color: "#fff",
        border: "1px solid",
        borderColor: theme.palette.primary.dark,
    },
    title: {
        fontWeight: 600,
    },
    customerCard: {
        padding: "100px 24px",
        height: "100vh",
        overflow: "auto",
    },
    drawerlabel: {
        marginTop: "10px",
        "& sup": {
            color: "red",
        },
    },
    drawerButtonTittle: {
        backgroundColor: "#0f2b79 !important",
        marginLeft: "10px",
        textTransform: "capitalize",
        color: "#fff",
        border: "1px solid",
        borderColor: theme.palette.primary.dark,
    },
    mapsubdrawerButton: {
        backgroundColor: "#0f2b79 !important",
        marginLeft: "10px",
        textTransform: "capitalize",
        color: "#fff",
        border: "1px solid",
        borderColor: theme.palette.primary.dark,
        marginTop: "-14px",
    },
    Drawer: {
        overflow: "hidden",
        "& .MuiDrawer-paper": {
            overflow: "hidden",
        },
    },
    // drawer styles

    closeIcondrawermap: {
        right: "520px",
        position: "fixed",
        display: "flex",
        color: theme.palette.primary.contrastText,
        cursor: "pointer",
        fontSize: 28,
        marginTop: 8,
        "& .MuiSvgIcon-root": {
            fontSize: "35px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "28px !important",
            },
        },
        [theme.breakpoints.down("sm")]: {
            position: "relative",
            right: "11px",
            color: theme.palette.primary.dark,
            top: "0px",
        },
    },
    createnewsection: {
        padding: "10px 20px",
        height: "100vh",
        overflow: "auto",
        paddingTop: "80px",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "16px",
            paddingRight: "16px",
        },
    },
    addonSection: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .MuiCheckbox-root": {
            padding: "5px",
            marginRight:'42px',
        },
    },
    addonSectionTittle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "20px 0px 10px 0px",
        "& .MuiTypography-root": {
            fontWeight: 600,
            fontSize: "16px",
        },
    },
    drawerwidth: {
        width: "500px",
        [theme.breakpoints.down("sm")]: {
            width: "318px",
        },
    },
    pricingDetailsMain: {
        marginTop: "20px",
        paddingTop: "15px",
        borderTop: "1px dashed",
        borderColor: theme.palette.primary.main,
    },
    customerDetailTittle: {
        fontWeight: 600,
        fontSize: "16px",
    },
    planrefelect: {
        "& .MuiTypography-root": {
            fontWeight: 600,
            fontSize: "16px",
        },
        "& .MuiCheckbox-root": {
            padding: "3px 0px",
        },
    },
}));
// end


// material UI styles
export const useStylesDetails = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        padding: "19px 12px 18px 19px",
        height: "95vh",
        width: "100%",
        overflow: "auto",
        "& sup": {
            color: "red",
        },
    },
    input: {
        marginBottom: 10,
    },
    label: {
        textAlign: "left",
    },
    customerForm: {
        backgroundColor: theme.palette.primary.contrastText,
        padding: "16px 0px",
        boxShadow: "0px 12px 53px #1d1f7212",
        borderRadius: 6,
    },
    customerInput: {
        padding: "0px 20px",
    },
    tab: {
        marginTop: "28px",
        padding: "10px 20px",
        height: "62px",
        "& .MuiTab-root.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderBottom: "none",
            outline: "none",
            borderRadius: 8,
            minHeight: "40px !important",
            maxHeight: "40px !important",
        },
        "& .MuiTabs-indicator": {
            display: "none",
        },
    },
    tabsTittle: {
        textTransform: "capitalize",
    },
    adminTittle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
    },
    createnewsection: {
        padding: "10px 20px",
        height: "100vh",
        overflow: "auto",
        paddingTop: "100px",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "16px",
            paddingRight: "16px",
        },
    },
    drawerlabel: {
        marginTop: "10px",
        "& sup": {
            color: "red",
        },
    },
    Header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: "14px",
        borderBottom: "1px solid #00000012",
        position: "absolute",
        width: "100%",
        top: "0",
        height: "77px",
        padding: "15px 24px 20px",
        backgroundColor: theme.palette.primary.contrastText,
        zIndex: "1",
    },
    title: {
        fontWeight: 600,
    },
    logo: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        "& .MuiTextField-root": {
            opacity: "0",
            position: "absolute",
            left: "0",
            zIndex: "1",
            borderRadius: "50%",
            "& .MuiOutlinedInput-input": {
                height: "89px",
            },
        },
    },
    mapSubscription: {
        width: "96%",
        height: "100%",
        minHeight: "200px",
        margin: "20px auto",
        backgroundColor: theme.palette.blue.light,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
        color: "#000000cf",
        padding: "20px 24px 20px",
        "& .MuiGrid-item": {
            paddingTop: "0px",
            paddingRight: "11px",
            [theme.breakpoints.down("sm")]: {
                paddingRight: "0px",
                paddingLeft: "0px",
            },
        },
        [theme.breakpoints.down("sm")]: {
            padding: "30px 34px 17px",
        },
    },
    drawerButtonTittle: {
        backgroundColor: "#0f2b79 !important",
        marginLeft: "10px",
        textTransform: "capitalize",
        color: "#fff",
        border: "1px solid",
        borderColor: theme.palette.primary.dark,
    },
    mapsubdrawerButton: {
        backgroundColor: "#0f2b79 !important",
        marginLeft: "10px",
        textTransform: "capitalize",
        color: "#fff",
        border: "1px solid",
        borderColor: theme.palette.primary.dark,
        marginTop: "-14px",
    },
    menu: {
        color: "red",
    },
    addonSection: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .MuiCheckbox-root": {
            padding: "5px",
            marginRight:'42px',
        },
    },
    addonSectionTittle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "20px 0px 10px 0px",
        "& .MuiTypography-root": {
            fontWeight: 600,
            fontSize: "16px",
        },
    },

    Drawer: {
        overflow: "hidden",
        "& .MuiDrawer-paper": {
            overflow: "hidden",
        },
    },
    pricingDetailsMain: {
        marginTop: "20px",
        borderTop: "1px dashed",
        borderColor: theme.palette.primary.main,
    },
    customerEmail: {
        marginRight: "5px",
        fontSize: "15px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "15px",
            marginRight: "0px",
        },
    },
    onTrailbutton: {
        border: "2px solid #0f2b79 !important",
        marginLeft: "10px",
        textTransform: "capitalize",
        color: "#0f2b79",
        fontWeight: 600,
        padding: "4px",
        borderRadius: "6px",
        fontSize: 13,
    },
    mainsubscriptionPlan: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
    },
    tittlesubscrition: {
        fontWeight: 600,
        [theme.breakpoints.only("sm")]: {
            fontSize: "15px",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "13px",
        },
    },
    subscriptionPlan: {
        textAlign: "left",
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
        },
    },
    subscrptionDetails: {
        marginTop: "5px",
        fontSize: "15px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            marginTop: "2px",
        },
    },
    subscrptionid: {
        marginTop: "5px",
        fontSize: "15px",
        [theme.breakpoints.down("sm")]: {
            marginTop: "5px",
            fontSize: "14px",
        },
    },
    amountpermonth: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: "15px 20px",
        borderRadius: "5px",
        [theme.breakpoints.down("sm")]: {
            width: "80px",
            margin: "10px 0px",
            padding: "0px 10px",
        },
    },
    subscriptionparent: {
        textAlign: "left",
        borderRight: "1px solid #00000026",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "10px",
            borderBottom: "1px solid #00000026",
            borderRight: "none",
        },
    },

    totalRevenue: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            marginTop: "20px",
        },
        "& p": {
            fontSize: 14,
        },
    },
    billingtab: {
        "& .MuiInputLabel-root": {
            marginTop: "2px",
        },
    },
    closeIcondraweradd: {
        right: "520px",
        position: "fixed",
        display: "flex",
        color: theme.palette.primary.contrastText,
        cursor: "pointer",
        fontSize: 28,
        marginTop: 8,
        [theme.breakpoints.down("sm")]: {
            position: "relative",
            right: "11px",
            color: theme.palette.primary.dark,
            top: "0px",
        },
    },
    closeIcondrawermap: {
        right: "520px",
        position: "fixed",
        display: "flex",
        color: theme.palette.primary.contrastText,
        cursor: "pointer",
        fontSize: 28,
        marginTop: 8,
        [theme.breakpoints.down("sm")]: {
            position: "relative",
            right: "11px",
            color: theme.palette.primary.dark,
            top: "0px",
        },
    },
    pricingDetailsTittle: {
        fontWeight: 600,
        fontSize: "16px",
    },
    pricingflexBoxTittle: {
        fontSize: "13px",
    },
    drawerwidth: {
        width: "500px",
        [theme.breakpoints.down("sm")]: {
            width: "318px",
        },
    },
}));
  // end