import { TextField, InputAdornment, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  inputPrams: {
    "& .MuiTypography-root": {
      fontSize: "16px",
      color: theme.palette.common.black,
    },
  },
  input: {
    width: "100%",
  },
}));
export const PricingInput = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <>
        <div className={classes.text}>
          <TextField
            className={classes.input}
            type={props.type}
            placeholder={props.placeholder}
            disabled={props?.disabled}
            fullWidth
            InputProps={{
                  startAdornment: (
                <InputAdornment className={classes.inputPrams} position="start">
                  {props?.tittle}
                </InputAdornment>
              ),


              endAdornment: (
                <InputAdornment className={classes.inputPrams} position="end">
                  $
                </InputAdornment>
              ),
            }}
            size="small"
            onChange={props.onChange}
            value={props?.amount ? props?.amount : null}
          />
        </div>
      </>
      {props.isError && (
        <Typography variant={"caption"} color={"error"}>
          {props.errorMessage}
        </Typography>
      )}
    </div>
  );
};
  // TextBox.propTypes = {
  //   value: PropTypes.string,
  //   label: PropTypes.string,
  //   id: PropTypes.string,
  //   placeholder: PropTypes.string,
  //   multiline: PropTypes.bool,
  //   type: PropTypes.string,
  //   isReadonly: PropTypes.bool,
  //   onChange: PropTypes.func,
  // };
  // TextBox.defaultProps = {
  //   label: "Textbox",
  //   multiline: false,
  //   type: "text",
  //   placeholder: "Type Here...",
  // };
