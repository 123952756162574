import { TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useStyles } from "./style";

export const TextBox = (props) => {
  const classes = useStyles(props);

  const getLabel = (props) => {
    return (
      <Typography className={classes.Label} noWrap>
        {props.label}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
            *
          </Typography>
        )}
      </Typography>
    );
  };

  return (
    <div className={classes.root}>
      <>
        {getLabel(props)}
        <div className={classes.text}>
          <TextField
            className={classes.textbox}
            type={props.type}
            onKeyPress={(e) => {
              if (props.type === "number") {
                if (e.key === "e") {
                  e.preventDefault();
                }
              }
            }}
            // autoComplete={false}
            id={props.id}
            placeholder={props.placeholder}
            variant={props.variant ?? "outlined"}
            fullWidth
            InputLabelProps={{
              shrink: false,
            }}
            inputProps={{
              readOnly: props?.isReadonly ?? false,
              autocomplete: props?.autocomplete
            }}
            InputProps={{
              endAdornment: props?.endAdornment,
              startAdornment: props?.startAdornment,
              style: {
                padding: props.multiline ? 0 : "none"
              }
            }}
            disabled={props?.isReadonly ?? false}
            size="small"
            multiline={props.multiline}
            rows={5}
            autoFocus={props?.autoFocus}
            rowsMax={10}
            onChange={props.onChange}
            value={props.value}
          // onKeyPress={props?.onKeyPress}
          />
        </div>
      </>
      {props.isError && (
        <Typography variant={"caption"} color={"error"}>
          {props.errorMessage}
        </Typography>
      )}
    </div>
  );
};

TextBox.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  type: PropTypes.string,
  isReadonly: PropTypes.bool,
  onChange: PropTypes.func,
};
TextBox.defaultProps = {
  label: "Textbox",
  multiline: false,
  type: "text",
  placeholder: "Type Here...",
};
