import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";
import { Routes } from "./routes";
// import PrivateRouter from "./privateRouter";
import CustomerDetails from "../screens/customers/customerDetails";
import SubscriptionDetails from "../screens/subscription/subscriptiondetails";
import PlanDetails from "../screens/plan/plandetails";

import {
  NotFound,
  CustomersParent,
  SubscriptionParent,
  DashboardParent,
  PlanParent,
  AddonsParent,
  ChargesParent,
  FeatureGroupParent,
  Login,
  ChangePassword,
  Settings,
  Coupons,
  Configuration
} from "./../screens";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* Login Route */}
        {/* <Route path={Routes.login}>
          <Login />
        </Route> */}

        {/* DashboardParent Route */}
        <Route exact path={Routes.login}>
          <Login />
        </Route>

        {/* Change Password */}
        <Route exact path={Routes.changePassword}>
          <ChangePassword />
        </Route>

        {/* DashboardParent Route */}
        <Route exact path={Routes.dashboard}>
          <DashboardParent />
        </Route>

        {/* CustomersParent Route */}
        <Route exact path={Routes.customers}>
          <CustomersParent />
        </Route>

        {/* CustomersDetails Route */}
        <Route exact path={Routes.customersdetails}>
          <CustomerDetails />
        </Route>

        {/* Subscription Route */}
        <Route exact path={Routes.subscription}>
          <SubscriptionParent />
        </Route>

        {/* Subscription Details */}
        <Route exact path={Routes.subscriptiondetails}>
          <SubscriptionDetails />
        </Route>

        {/* Plan Route */}
        <Route exact path={Routes.plan}>
          <PlanParent />
        </Route>

        {/* Plan Details Routes */}
        <Route exact path={Routes.plandetails}>
          <PlanDetails />
        </Route>

        {/* Add-on Routes */}
        <Route exact path={Routes.addons}>
          <AddonsParent />
        </Route>

        {/* Charges Routes */}
        <Route exact path={Routes.charges}>
          <ChargesParent />
        </Route>

        {/*features Routes */}
        <Route exact path={Routes.features}>
          <FeatureGroupParent />
        </Route>

        {/*Coupon Routes */}
        <Route exact path={Routes.coupons}>
          <Coupons />
        </Route>

        {/*Configuration Routes */}
        <Route exact path={Routes.configuration}>
          <Configuration />
        </Route>

        {/*settings Routes */}
        <Route exact path={Routes.settings}>
          <Settings />
        </Route>

        {/* For unknow/non-defined path */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
