import { useStylesManagement } from "./style";
import { Grid, Box, Typography, Button } from "@mui/material";
import React, { useMemo, useState } from 'react';
import { TopNavBar, AlertDialog, SelectBox, TextBox } from "../../components";
import { useHistory } from 'react-router-dom';
import { AddMemberCard } from './addfigurecard';
import { useWindowDimensions, AlertProps, LocalStorageKeys } from "../../utils";

import { BackdropContext, AlertContext } from "../../contexts";

import moment from "moment";


const preference = [
    {
        value: 'String preferred number',
        label: "String preferred number",

    },
    {
        value: 'Date preferred number',
        label: "Date preferred number",
        format: [
            {
                value: 'YYYY',
                label: "YYYY"
            },
            {
                value: 'YYYYMM',
                label: "YYYYMM"
            },
            {
                value: 'YYYYMMDD',
                label: "YYYYMMDD"
            },
        ]
    },
    {
        value: 'String and date preferred number',
        label: "String and date preferred number",
        format: [
            {
                value: 'YYYY',
                label: "YYYY"
            },
            {
                value: 'YYYYMM',
                label: "YYYYMM"
            },
            {
                value: 'YYYYMMDD',
                label: "YYYYMMDD"
            },
        ]
    },
]

const initial = {
    open: false,
    view: false,
    seedvaluepreview: "",
    preference: "",
    fromat: "",
    number: "",
    seed: "",
    String: "",
    list: [],
    listID: {},
    isShow: false,
    isEdit: false,
    error: {
        preference: "",
        fromat: "",
        number: "",
        seed: "",
        String: "",
    }
}
const limit = 20;
export const Configure = () => {

    const history = useHistory();
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);
    const classes = useStylesManagement();
    const size = useWindowDimensions();
    const [state, setState] = useState({ ...initial })
    const [list, setList] = useState({
        count: null,
        list: [],
    })
    const [view, setView] = useState({})
    const [loading, setLoading] = React.useState(true);
    const [offset, setOffset] = React.useState(0);

    const closeDrawer = () => {
        setState({ ...initial })
    }

    const openDrawer = (v, x, detail) => {
        if (detail === 'edit') {
            let maxDigit = Number(x?.starts_with?.toString());
            let addZero = "";
            if (maxDigit > x?.starts_with?.length) {
                let appendDigit = maxDigit - x?.starts_with?.length;
                let i = appendDigit;
                while (i > 0) {
                    addZero += '0';
                    i--;
                }
            }
            setState({
                open: true,
                view: v,
                seedvaluepreview: addZero + x?.starts_with?.toString(),
                preference: {
                    value: x?.preference ?? "",
                    label: x?.preference ?? ""
                },
                fromat: {
                    value: x?.date_format ?? "",
                    label: x?.date_format ?? "",
                },
                number: x?.starts_with?.toString()?.length,
                seed: x?.starts_with ?? "",
                String: x?.prefix ?? "",
                list: [],
                listID: x,
                isShow: true,
                isEdit: true,
                error: {
                    preference: "",
                    fromat: "",
                    number: "",
                    seed: "",
                    String: "",
                }
            })
        }
        else if (detail === 'view') {
            setView(x)
            setState({ ...state, open: true, view: v, listID: x })
        }
        else {
            setState({ ...state, open: true, view: v, listID: x })
        }

    }

    const updateState = (k, v) => {
        let error = state?.error;
        error[k] = "";
        if (k === "seed") {
            let maxDigit = Number(state?.number);
            let addZero = "";
            if (maxDigit > v.length) {
                let appendDigit = maxDigit - v.length;
                let i = appendDigit;
                while (i > 0) {
                    addZero += '0';
                    i--;
                }
            }
            setState({ ...state, seedvaluepreview: addZero + v, seed: v, isShow: true, error })
        }
        else {

            if (k === 'preference') {
                setState({ ...state, String: "", seed: "", number: "", fromat: "", isShow: false, [k]: v })
            }
            else if (k === 'number') {
                setState({ ...state, seed: "", [k]: v })
            }
            else {
                setState({ ...state, [k]: v })
            }

        }
    }

    // const getRoles = (offset = 0, k) => {
    //     backdrop.setBackDrop({
    //         ...backdrop,
    //         open: true,
    //         message: "Loading",
    //     });
    //     ({
    //         query: {},
    //         fetchPolicy: 'network-only',
    //         variables: {
    //             offset,
    //             limit,
    //             client: localStorage.getItem(LocalStorageKeys.clinetID)
    //         }
    //     }).then((res) => {
    //         if (k === 'scroll') {
    //             setList({
    //                 count: res?.data?.count ?? null,
    //                 list: [...list?.list, ...res?.data?.sequence_config ?? []]
    //             })
    //         }
    //         else {
    //             setList({
    //                 count: res?.data?.count ?? null,
    //                 list: res?.data?.sequence_config ?? []
    //             })
    //         }

    //         setLoading(false);
    //         backdrop.setBackDrop({
    //             ...backdrop,
    //             open: false,
    //             message: "",
    //         });
    //     }).catch((err) => {
    //         setLoading(false);
    //     })
    // }

    const save = async () => {
        if (validate()) {
            closeDrawer()
            backdrop.setBackDrop({
                ...backdrop,
                open: true,
                message: "Loading",
            });
            let result = await ({
                mutation: {},
                variables: {
                    ID: state?.listID?.id ?? undefined,
                    updatePayload: {
                        preference: state?.preference?.value,
                        prefix: state?.String ?? null,
                        date_format: state?.fromat?.value ?? null,
                        starts_with: state?.seedvaluepreview ?? null,
                        is_active: true,
                        seedvalue: state?.number ?? null,
                        updated_at: new Date().toISOString(),
                        client: localStorage.getItem(LocalStorageKeys.clinetID)
                    }
                }
            })
            if (result) {
                // getRoles()
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `${state?.isEdit ? "Updated Successfully" : "Created Successfully"}`,
                });
                setState({ ...initial })
            }
            else {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                });
            }
        }


    }

    const DateToday = useMemo(() => (
        [
            {
                date: state?.fromat?.label?.length > 0 ? moment(new Date()).format(state?.fromat?.label) : null,
                name: Number(state?.seedvaluepreview) ?? "-",
                value: state?.String ?? null
            },
            {
                date: state?.fromat?.label?.length > 0 ? moment(new Date()).format(state?.fromat?.label) : null,
                name: Number(state?.seedvaluepreview) + 1 ?? "-",
                value: state?.String ?? null

            },
            {
                date: state?.fromat?.label?.length > 0 ? moment(new Date()).format(state?.fromat?.label) : null,
                name: Number(state?.seedvaluepreview) + 2 ?? "-",
                value: state?.String ?? null

            },
        ]
        // eslint-disable-next-line
    ), [state?.seed])

    const validate = () => {
        let isValid = true;
        let error = state.error;
        if (state?.preference?.length === 0) {
            isValid = false;
            error.preference = "Preference is Required";
        }
        if (state?.preference?.value === "String and date preferred number" || state?.preference?.value === "Date preferred number" || state?.preference?.length === 0) {
            if (state?.fromat?.length === 0) {
                isValid = false;
                error.fromat = "Date Format is Required";
            }
        }
        if (state?.seed?.length === 0) {
            isValid = false;
            error.seed = "Seed Value is Required";
        }
        if (state?.preference?.value === "String and date preferred number" || state?.preference?.value === "String preferred number" || state?.preference?.length === 0) {
            if (state?.String?.length === 0) {
                isValid = false;
                error.String = "Prefix is Required";
            }
        }

        setState({ ...state, error });

        return isValid;
    };



    React.useEffect(() => {
        // getRoles()
        // eslint-disable-next-line
    }, [])

    let listModelData = [
        {
            "id": 18,
            "slug": "Visitor gate pass",
            "table_name": "Visitor gate pass",
            "date_format": "YYYYMM",
            "starts_with": 10,
            "prefix": "",
            "preference": "Date preferred number"
        },
        {
            "id": 10,
            "slug": "agreement",
            "table_name": "agreement",
            "date_format": "YYYYMM",
            "starts_with": 1,
            "prefix": "AGR",
            "preference": "Date preferred number"
        },
        {
            "id": 8,
            "slug": "agreement_rent_breakup",
            "table_name": "agreement_rent_breakup",
            "date_format": "YYYYMMDD",
            "starts_with": 14,
            "prefix": "",
            "preference": "Date preferred number"
        },
        {
            "id": 17,
            "slug": "agreement_units",
            "table_name": "agreement_units",
            "date_format": "YYYYMM",
            "starts_with": 1,
            "prefix": "AGU",
            "preference": "Date preferred number"
        },
        {
            "id": 2,
            "slug": "delivery order collection",
            "table_name": "delivery order collection",
            "date_format": "YYYY",
            "starts_with": 1,
            "prefix": "",
            "preference": "Date preferred number"
        },
        {
            "id": 16,
            "slug": "domestic help request",
            "table_name": "domestic help request",
            "date_format": "YYYY",
            "starts_with": 1,
            "prefix": "",
            "preference": "Date preferred number"
        },
        {
            "id": 6,
            "slug": "general_request",
            "table_name": "general_request",
            "date_format": "YYMMDD",
            "starts_with": 1,
            "prefix": "GN",
            "preference": "Date preferred number"
        },
        {
            "id": 13,
            "slug": "Lead",
            "table_name": "lead",
            "date_format": "YYYY",
            "starts_with": 4,
            "prefix": "LEAD",
            "preference": "String and date preferred number"
        },
        {
            "id": 7,
            "slug": "maintenance_request",
            "table_name": "maintenance_request",
            "date_format": "YYMMDD",
            "starts_with": 1,
            "prefix": "MN",
            "preference": "Date preferred number"
        },
        {
            "id": 21,
            "slug": "Property",
            "table_name": "property",
            "date_format": null,
            "starts_with": 6,
            "prefix": "PRT",
            "preference": "String preferred number"
        },
        {
            "id": 11,
            "slug": "proxy_lead",
            "table_name": "proxy_lead",
            "date_format": "YYYYMM",
            "starts_with": 1,
            "prefix": "",
            "preference": "Date preferred number"
        },
        {
            "id": 1,
            "slug": "Quotation",
            "table_name": "quotation",
            "date_format": "YYYYMM",
            "starts_with": 6,
            "prefix": "QUOTE",
            "preference": "String and date preferred number"
        },
        {
            "id": 22,
            "slug": "quotation_payment_schedule",
            "table_name": "quotation_payment_schedule",
            "date_format": "YYYMMDD",
            "starts_with": 1,
            "prefix": "MI",
            "preference": "String and date preferred number"
        },
        {
            "id": 5,
            "slug": "unit_amenities_breakup",
            "table_name": "unit_amenities_breakup",
            "date_format": null,
            "starts_with": 0,
            "prefix": "123",
            "preference": "String preferred number"
        },
        {
            "id": 3,
            "slug": "CUSTOMER",
            "table_name": "user_profiles",
            "date_format": "",
            "starts_with": 1,
            "prefix": "INV",
            "preference": "String preferred number"
        }
    ];


    const handleInfiniteScroll = () => {
        if (offset + limit <= list?.count[0]?.count) {
            setOffset((prevOffset) => prevOffset + limit);
            setLoading(true);
            // getRoles(offset + limit, 'scroll');
        }
    }

    return (
        <div>
            <TopNavBar
                title={"Configuration"}
            // isSearch
            // buttonLabel="Create Coupons"
            // isFilter
            // functions={createPlan}
            />
            <div style={{ padding: "24px", paddingBottom: '150px', margin: "4px", height: '100vh', overflow: 'auto' }}>
                <AddMemberCard
                    closeDrawer={closeDrawer}
                    data={listModelData}
                    openDrawer={openDrawer}
                    handleInfiniteScroll={handleInfiniteScroll}
                    loading={loading}
                />
            </div>

            <AlertDialog
                isNormal
                header={state?.view ? 'View Configuration' : state?.isEdit ? 'Edit Configuration' : 'Add Configuration'}
                onClose={closeDrawer}
                open={state?.open}
                component={
                    <>
                        {
                            state?.view ?
                                <div>
                                    <div style={{ textAlign: "left", padding: "24px" }}>

                                        <Box className={classes.detailsBox}>
                                            {
                                                view?.preference?.length > 0 &&
                                                <>
                                                    <Box className={classes.box}>
                                                        <Typography className={classes.title}>Number Preference</Typography>
                                                        <Typography className={classes.sub1}>{view?.preference ?? "-"}</Typography>
                                                    </Box>
                                                </>
                                            }
                                            {
                                                view?.prefix?.length > 0 &&
                                                <>
                                                    <Box className={classes.box}>
                                                        <Typography className={classes.title}>Prefix</Typography>
                                                        <Typography className={classes.sub1}>{view?.prefix ?? "-"}</Typography>
                                                    </Box>
                                                </>
                                            }
                                            {
                                                view?.starts_with?.toString?.length &&
                                                <>
                                                    <Box className={classes.box}>
                                                        <Typography className={classes.title}>Minimum Number Of Digits</Typography>
                                                        <Typography className={classes.sub1}>{view?.starts_with?.toString?.length ?? "-"}</Typography>
                                                    </Box>
                                                </>
                                            }
                                            {
                                                view?.starts_with?.length > 0 &&

                                                <Box className={classes.box}>
                                                    <Typography className={classes.title}>Seed Value</Typography>
                                                    <Typography className={classes.sub1}>{view?.starts_with ?? "-"}</Typography>
                                                </Box>
                                            }

                                        </Box>

                                    </div>
                                    <div style={{ textAlign: "left", padding: "24px" }}>
                                        <Box className={classes.detailsBox}>
                                            <Box className={classes.box}>
                                                <Typography className={classes.title}>Preview</Typography>
                                                <Typography className={classes.sub1}>{view?.prefix ?? "-"}{view?.prefix && "-"}{view?.starts_with ?? "-"}</Typography>
                                            </Box>
                                        </Box>
                                    </div>
                                    <div style={{ padding: "24px" }}>
                                        <Button className={classes.button} variant="contained" onClick={(e) => {
                                            e.stopPropagation()
                                            openDrawer(false, view, "edit")
                                        }} >Edit</Button>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div style={{
                                        height: size?.height - 300, textAlign: "left",
                                        margin: "9px 0px", overflow: 'auto', padding: "24px"
                                    }}
                                    >

                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <SelectBox
                                                    isRequired
                                                    label="Number Preference"
                                                    placeholder="Select Number Preference"
                                                    options={preference ?? []}
                                                    value={state?.preference ?? ""}
                                                    onChange={(value) => {
                                                        updateState("preference", value);
                                                    }}
                                                    isError={state?.error?.preference?.length > 0}
                                                    errorMessage={state?.error?.preference} />
                                            </Grid>
                                            {
                                                state?.preference?.value === "String and date preferred number" &&
                                                <Grid item xs={12}>
                                                    <TextBox
                                                        isrequired
                                                        label="Prefix"
                                                        placeholder="Prefix"
                                                        value={state?.String ?? ""}
                                                        onChange={(e) => {
                                                            updateState("String", e.target.value);
                                                        }}
                                                        isError={state?.error?.String?.length > 0}
                                                        errorMessage={state?.error?.String} />
                                                </Grid>
                                            }

                                            <Grid item xs={12}>
                                                {
                                                    state?.preference?.value === "String preferred number" ?
                                                        <TextBox
                                                            isrequired
                                                            label="Prefix"
                                                            placeholder="prefix"
                                                            value={state?.String ?? ""}
                                                            onChange={(e) => {
                                                                updateState("String", e.target.value);
                                                            }}
                                                            isError={state?.error?.String?.length > 0}
                                                            errorMessage={state?.error?.String} />
                                                        :
                                                        <SelectBox
                                                            isRequired
                                                            label="Fromat"
                                                            placeholder="Select Fromat"
                                                            options={state?.preference?.format ?? []}
                                                            value={state?.fromat ?? ""}
                                                            onChange={(value) => {
                                                                updateState("fromat", value);
                                                            }}
                                                            isError={state?.error?.fromat?.length > 0}
                                                            errorMessage={state?.error?.fromat} />
                                                }

                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextBox
                                                    isrequired
                                                    type="number"
                                                    label="Min Number"
                                                    placeholder="Min Number"
                                                    value={state?.number ?? ""}
                                                    onChange={(e) => {
                                                        updateState("number", e.target.value);
                                                    }}
                                                    isError={state?.error?.number?.length > 0}
                                                    errorMessage={state?.error?.number} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextBox
                                                    isrequired
                                                    type="number"
                                                    label="Seed Value"
                                                    placeholder="Seed Value"
                                                    value={state?.seed ?? ""}
                                                    onChange={(e) => {
                                                        updateState("seed", e.target.value);
                                                    }}
                                                    isError={state?.error?.seed?.length > 0}
                                                    errorMessage={state?.error?.seed} />
                                            </Grid>
                                            {
                                                state?.isShow && state?.seedvaluepreview?.length > 0 &&
                                                <Grid item xs={12}>
                                                    <Typography className={classes.preview}>Preview</Typography>
                                                    <Box style={{ marginTop: '6px' }}>
                                                        {
                                                            DateToday?.map((x) => {
                                                                return (
                                                                    <Typography className={classes.previewdetails}>
                                                                        {x?.value?.length > 0 && x?.value}
                                                                        {x?.value?.length > 0 && '-'}
                                                                        {x?.date !== null && x?.date}
                                                                        {x?.date !== null && '-'}
                                                                        {x?.name}</Typography>
                                                                )
                                                            })
                                                        }

                                                    </Box>

                                                </Grid>

                                            }

                                        </Grid>
                                    </div>
                                    <div style={{ padding: "20px 24px" }}>
                                        <Button className={classes.button} variant="contained" onClick={save}>{state?.isEdit ? "Save Configuration" : "Add Configuration"}</Button>
                                    </div>
                                </div>

                        }
                    </>

                }
            />
        </div>
    )
}