import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { TopNavBar } from "../../components";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        marginTop: "20%"
    }
}));

export const Dashboard = props => {

    const { t } = useTranslation(["topBar"]);

    const classes = useStyles();

    return <div>

        {/* Topbar bar */}
        <TopNavBar
            title={t("dashboard")}
            isSearch
        />
        {/* end */}

        <div className={classes.root}>

            Dashboard

        </div>

    </div>
};