import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { CunstomertableJson } from "./common";
// import { Typography, Button, Modal, Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import { TopNavBar, TableComponent } from "../../components";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";
import { config } from "../../config";
import { BackdropContext } from "../../contexts";
// import { AlertProps } from "../../utils";
import { Routes } from "../../router/routes";
import { useTranslation } from "react-i18next";

// material UI styles
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
    textAlign: "center",
  },
  button: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: 10,
  },
}));
// end

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   borderRadius: "12px",
//   boxShadow: 24,
//   p: 2,
// };

export const Customers = (props) => {
  // use classes
  const classes = useStyles();
  const history = useHistory();
  // end

  const { t } = useTranslation(["topBar"]);

  // const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);

  const [action, setAction] = React.useState(false);
  const [tData, setTdata] = React.useState([]);

  const [tempData, setTempData] = React.useState([]);

  const [switchActive, setSwitchActive] = React.useState(false);
  const [searchkey, setSearchkey] = React.useState("");

  const handleFilterSwitchChange = (event) => {
    setSwitchActive(event.target.checked);
  };

  const showAction = (action) => {
    setAction(action.length > 0 ? true : false);
  };

  // go to customer details page
  const isPushCustomerDetailPage = () => {
    history.push(Routes.customersdetails);
  };

  // All Customer
  React.useEffect(() => {
    getAll(searchkey, switchActive);
    // eslint-disable-next-line
  }, [switchActive])

  const getAll = (search, filter) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });

    let params = {
      search: search ? search : "",
      filter: filter
    }

    NetworkCall(
      `${config.api}api/v1/auth/customer/get/all`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        setTdata(main?.data?.rows);
        if (search?.length === 2) { setTempData(main?.data?.rows) }
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // on Edit
  const onEdit = (cell, param, e) => {
    e.stopPropagation();
    history.push({
      pathname: Routes.customersdetails,
      state: {
        isEdit: true,
        data: param.row.id,
      },
    });
  };

  const handleRowclick = (param) => {
    history.push({
      pathname: Routes.customersdetails,
      state: {
        isView: true,
        data: param.row.id,
      },
    });
  };

  const onCustomerSearch = (val = "") => {

    let search = val?.trim();
    setSearchkey(search)
    if (search?.length === 2) {
      // search throught DB
      getAll(search, switchActive)
    }

    if (search?.length >= 3) {
      // local search 
      let locSearchData = tempData?.filter((item) => {
        if ((item?.id?.toLowerCase()?.indexOf(search?.toLowerCase()) !== -1) || (item?.company_name?.toLowerCase()?.indexOf(search?.toLowerCase()) !== -1) || (item?.business_phone?.toLowerCase()?.indexOf(search?.toLowerCase()) !== -1) || (item?.company_mail_id?.toLowerCase()?.indexOf(search?.toLowerCase()) !== -1)) {
          return item
        }
      })
      setTdata(locSearchData);
    }

    if (search?.length === 0) {
      getAll(search, switchActive)
    }

  }


  return (
    <div>
      {/* Topbar bar */}

      <TopNavBar
        title={t("customers")}
        isSearch
        buttonLabel="Create New"
        isAction
        isFilter
        functions={isPushCustomerDetailPage}
        action={action}
        onChangeSearch={onCustomerSearch}
        handleFilterSwitchChange={handleFilterSwitchChange}
        switchActive={switchActive}
      />

      {/* end */}

      {/* content */}

      <div className={classes.root}>
        <TableComponent
          dataList={tData}
          Header={CunstomertableJson(onEdit)}
          isChecked
          showAction={showAction}
          cellClick={(rowData) => handleRowclick(rowData)}
        />
        {/* end */}
      </div>
    </div>
  );
};
