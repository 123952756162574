import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  ListItemText,
  Typography,
  ListItem,
  Paper,
  Avatar,
  Box,
  Popper,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { SwitchBoxParent } from "../../../components";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Routes } from "../../../router/routes";
import LogoutIcon from "@mui/icons-material/Logout";

// importing for side navbar menu listing
import { SideNavbarMenu } from "../../../utils";

import { useTranslation } from "react-i18next";

// material UI styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: "0px 20px 20px 0px",
    position: "relative",
    boxShadow:
      "0px 1px 8px rgb(0 0 0 / 20%), 0px 3px 3px rgb(0 0 0 / 12%), 0px 3px 4px rgb(0 0 0 / 14%)",
  },
  change: {
    display: "flex",
    justifyContent: "center",
  },
  drawer: {
    width: "100%",
    position: "relative",
    height: "100vh",
    overflow: "hidden",
  },
  avatar: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
    float: "left",
  },
  userSection: {
    width: "100%",
    bottom: 0,
    left: 0,
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #8080801f",
    backgroundColor: theme.palette.primary.contrastText,
    [theme.breakpoints.up("md")]: {
      position: "absolute",
    },
  },
  menulist: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    padding: "0px 10px",
  },
  more: {
    float: "right",
    textAlign: "center",
    marginTop: "10px",
  },
  title: {
    fontWeight: 600,
    margin: "20px 10px 0px",
    paddingBottom: "14px",
    borderBottom: "1px solid #00000012",
  },
  footerTitle: {
    fontSize: 15,
    fontWeight: 700,
  },
  logout: {
    cursor: "pointer",
    borderBottom: "1px solid grey",
    padding: 5,
    textAlign: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
  },
  listView: {
    overflow: "auto",
    height: "79vh",
    paddingBottom: "30px",
    "& .MuiTypography-root": {
      marginRight: "10px",
    },
    "& .MuiListItem-root": {
      display: "-webkit-box",
    },
    "& .MuiSvgIcon-root": {
      display: "flex",
    },
    "& .MuiListItem-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight: 600,
      "& .MuiTypography-root": {
        fontWeight: 500,
        fontSize: "17px",
      },
    },
  },
  menuIcon: {
    minWidth: "auto",
    padding: 8,
  },
}));
// end

export const SideNavBar = (props) => {

  const { t } = useTranslation(["sidebar"]);

  // use classes
  const classes = useStyles(props);
  // end

  // use location history
  const history = useHistory();
  // end

  // to maintain state
  const [anchorEl, setAnchorEl] = React.useState(null);
  // end

  const isSelected = (data) => {
    if (data.link || data?.sub_link) {
      return history.location.pathname === (data?.link || data?.sub_link) || history.location.pathname === (data?.sub_link);
    }
  };

  const onListClick = (data) => {
    if (data.link) {
      history.push(data.link);
    }
  };

  //  Logout
  const onLogOut = () => {
    localStorage.clear();
    history.push(Routes.login);
  };

  // popper functions
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  // end

  return (
    <div className={classes.root}>
      <Paper className={classes.drawer} square>
        {/* sidebar title */}
        <Typography variant="h6" className={classes.title} noWrap>
          {t("app_name")}
        </Typography>
        {/* end */}

        <div className={classes.listView}>
          {/* switch prod card */}
          <SwitchBoxParent />
          {/* end */}

          {/* to looping sidebar menulisting */}
          {SideNavbarMenu.map((navBar, index) => (
            <ListItem
              className={classes.menulist}
              onClick={(e) => onListClick(navBar)}
              button
              key={index}
              selected={isSelected(navBar)}
            >
              <Box style={{ display: "flex", margin: "5px 0px" }}>
                {/* icons */}
                <ListItemText primary={navBar?.icon} />

                {/* title */}
                <ListItemText primary={t(navBar.tittle)} />
              </Box>
              {/* end */}
            </ListItem>
          ))}
          {/* end */}
        </div>

        {/* sidebar footer user logo and more icon */}
        <Box className={classes.userSection}>
          <Typography variant="body1" className={classes.footerTitle}>
            <Avatar
              className={classes.avatar}
              src="https://mui.com/static/images/avatar/2.jpg"
              alt="Remy Sharp"
            />{" "}
            <div style={{ margin: "10px 50px 0px" }}>
              {localStorage.getItem("user_name")}
            </div>
          </Typography>

          <Tooltip title="Logout" placement="right">
            <IconButton>
              <LogoutIcon onClick={() => onLogOut()} />
            </IconButton>
          </Tooltip>

          {/* <MoreHorizIcon onClick={(e) => handleClick(e)} style={{cursor:"pointer"}}/> */}
        </Box>
        {/* end */}
      </Paper>

      {/* popper contant */}
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <Paper>
          <Typography onClick={() => onLogOut()} className={classes.logout}>
            Logout
          </Typography>
        </Paper>
      </Popper>
      {/* end */}
    </div>
  );
};
