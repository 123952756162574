import makeStyles from "@mui/styles/makeStyles";

export const useStylesSettings = makeStyles((theme) => ({
    root: {
        marginLeft: "12px",
        marginTop: "12px",
        position: "relative"
    },
    paperGrid: {
        position: "absolute"
    },
    paper: {
        width: "95%",
        height: "80vh",
        padding: "10px",
        overflowX: 'auto'
    },
    headingTypo: {
        height: "142px",
        display: "flex",
        marginTop: "10px",
        flexDirection: 'column'
    },
    headingTypo1: {
        fontSize: "18px",
        fontWeight: "bolder",
        textAlign: "left",
        color: "#091B29"
    },
    headingTypo2: {
        fontSize: "12px",
        textAlign: "left",
        wordWrap: "break-word",
        color: "#98A0AC"
    },
    planParentDiv: {
        height: "142px",
        display: "flex"
    },
    planDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    planName: {
        fontSize: "20px",
        fontWeight: "bolder",
        textAlign: "center",
        marginTop: "2px",
    },
    amount: {
        fontSize: "12px",
        textAlign: "center",
        marginTop: "2px",
        color: "#4E5A6B"
    },
    planDesc: {
        fontSize: "8px",
        textAlign: "center",
        wordWrap: "break-word",
        marginTop: "2px",
        color: "#98A0AC"
    },
    planbtn: {
        height: "30px",
        width: "80%",
        marginTop: "6px",
        backgroundColor: "#5078E1",
        textTransform: "none",
        whiteSpace: "nowrap"
    },
    Accordion: {
        background: "#F5F7FA 0% 0% no-repeat padding-box",
        boxShadow: "none",
        // width: "108%",
        '@media (max-width:1024px)': {
            width: "108%"
        }
    },
    AccordionSummary: {
        backgroundColor: "#F5F7FA",
        marginTop: "8px"
    },
    featureGroupName: {
        color: "#091B29",
        fontWeight: "800"
    },
    featureNameDiv: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    featureName: {
        textAlign: 'left',
        color: "#98A0AC",
        fontWeight: "600",
        fontSize: "14px"
    },
    featureNameDivider: {
        height: "50px"
    },
    checkCircle: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: "center"
    },
    DividerIcon: {
        justifyContent: "flex-end",
        height: "50px"
    }
}));
