import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Typography,
  Dialog,
  TextField,
  DialogContent,
  DialogTitle,IconButton,DialogActions,Button
} from "@mui/material";
import { VisibilityOff,Visibility,Close } from "@mui/icons-material";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";
import { config } from "../../config";

// material UI styles
const useStyles = makeStyles((theme) => ({  
    drawer: {
      width: "100%",
      position: "relative",
      height: "100vh",
      overflow: "hidden",
    },
   
    title: {
      fontWeight: 600,
      margin: "20px 10px 0px",
      paddingBottom: "14px",
      borderBottom: "1px solid #00000012",
    },
    footerTitle: {
      fontSize: 15,
      fontWeight: 700,
    },
    logout:{
      cursor:"pointer",
      borderBottom:"1px solid grey",padding:5,textAlign:"center"
    },
    item:{
    display:"flex",
    flexDirection:'column',
    padding:10
    },
    listView: {
      overflow: "auto",
      height: "79vh",
      paddingBottom: "30px",
      "& .MuiTypography-root": {
        marginRight: "10px",
      },
      "& .MuiListItem-root": {
        display: "-webkit-box",
      },
      "& .MuiSvgIcon-root": {
        display: "flex",
      },
      "& .MuiListItem-root.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontWeight: 600,
        "& .MuiTypography-root": {
          fontWeight: 500,
          fontSize: "17px",
        },
      },
    },
    menuIcon: {
      minWidth: "auto",
      padding: 8,
    },
  }));
  // end

export const ChangePassword =()=>{

    const classes = useStyles();

    const [openD,setOpenD] = React.useState(false)

    const [showPassword,setPassword] = React.useState(
        {
          old:false,
          new:false,
          cnfrm:false
        }
        );

    const [values,setValues]=React.useState({
        old_password:"",
        new_password:"",
        confirm_password:"",
        error: {
          confirm_password: "",
        },
      })

       // Password Show
  const handleClickShowPassword = (key) => {
    setPassword({[key]:!showPassword[key]})
   };
  
    // Handle Change
  const handleChange=(key,e)=>{
    setValues({...values,[key]:e})
  };

   // Validate
   const isIamValideToLogin = () => {
    let isValid = true;
    let error = values.error;

    if (values.confirm_password !== values.new_password) {
      isValid = false;
      error.email = "New Password Does not Match the Old";
    }

    setValues({ ...values, error });
    return isValid;
    };

     // Bootstrap Dialog Title
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

    // Change password
    const onChangePassword =()=>{
        if (isIamValideToLogin()) {
          var payload={
            user_id: localStorage.getItem('profile_id'),
            oldpassword: values.old_password,
            newpassword: values.new_password
          }
          NetworkCall(
            `${config.api}api/v1/auth/changepassword`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
          )
          .then((response) => {
          })
          .catch((err) =>{
    
          })
            
        } else {
          return false;
        }
      }

    return(
      <Dialog
    open={openD}
    onClose={()=>setOpenD(false)}
    >
      <BootstrapDialogTitle onClose={()=>setOpenD(false)}>Change Password</BootstrapDialogTitle>
      <DialogContent>
         <div className={classes.item}>
           <label>Old Password</label>
           <TextField
             type={showPassword.old ? "text" : "password"}
             value={values.old_password}
             onChange={(e) => handleChange("old_password", e.target.value)}
             InputProps={{
               endAdornment: showPassword.old ? (
                 <VisibilityOff
                   onClick={()=>handleClickShowPassword('old')}
                   style={{ cursor: "pointer" }}
                 />
               ) : (
                 <Visibility
                   onClick={()=>handleClickShowPassword('old')}
                   style={{ cursor: "pointer" }}
                 />
               ),
             }}
           />
         </div>
         <div className={classes.item}>
           <label>New Password</label>
           <TextField
             type={showPassword.new ? "text" : "password"}
             value={values.new_password}
             onChange={(e) => handleChange("new_password", e.target.value)}
             InputProps={{
               endAdornment: showPassword.new ? (
                 <VisibilityOff
                   onClick={()=>handleClickShowPassword('new')}
                   style={{ cursor: "pointer" }}
                 />
               ) : (
                 <Visibility
                   onClick={()=>handleClickShowPassword('new')}
                   style={{ cursor: "pointer" }}
                 />
               ),
             }}
           />
         </div>
         <div className={classes.item}>
           <label>Confirm Password</label>
           <TextField
             type={showPassword.cnfrm ? "text" : "password"}
             value={values.confirm_password}
             onChange={(e) => handleChange("confirm_password", e.target.value)}
             InputProps={{
               endAdornment: showPassword.cnfrm ? (
                 <VisibilityOff
                   onClick={()=>handleClickShowPassword('cnfrm')}
                   style={{ cursor: "pointer" }}
                 />
               ) : (
                 <Visibility
                   onClick={()=>handleClickShowPassword('cnfrm')}
                   style={{ cursor: "pointer" }}
                 />
               ),
             }}
           />
            {values.error.confirm_password !== "" && (
             <Typography style={{ fontSize: "10px", color: "red" }}>
               {values.error.confirm_password}
             </Typography>
           )}
         </div>
      </DialogContent>
      <DialogActions className={classes.change}>
       <Button autoFocus variant="contained" onClick={()=>onChangePassword()}>
         Change
       </Button>
     </DialogActions>
       
    </Dialog>
    )
    
}