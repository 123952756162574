import React from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PersonIcon from "@mui/icons-material/Person";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Routes } from "../../router/routes";

const useStyles = makeStyles((theme) => ({
  mainsubscriptionPlan: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  tittlesubscrition: {
    fontWeight: 600,
    fontSize: "16px",
    [theme.breakpoints.only("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "13px",
    },
  },
  subscriptionPlan: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  subscrptionDetails: {
    marginTop: "5px",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      marginTop: "2px",
    },
  },
  subscrptionid: {
    marginTop: "5px",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5px",
      fontSize: "14px",
    },
  },
  amountpermonth: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: "15px 20px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      margin: "10px 0px",
      padding: "0px 10px",
    },
  },
  subscriptionparent: {
    textAlign: "left",
    borderRight: "1px solid #00000026",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "10px",
      borderBottom: "1px solid #00000026",
      borderRight: "none",
    },
  },

  totalRevenue: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
    "& p": {
      fontSize: 14,
    },
  },

  customerName: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 24px",
    alignItems: "center",
  },
  drawerButtonTittle: {
    backgroundColor: "#0f2b79 !important",
    marginLeft: "10px",
    textTransform: "capitalize",
    color: "#fff",
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  },
  usernumber: {
    display: "flex",
    marginTop: "10px",
    fontSize: 10,
    "& .MuiTypography-root": {
      marginLeft: "5px",
    },
  },
  userDate: {
    display: "flex",
    alignItems: "center",
  },
  downlodsection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "21px",
  },
  subtittle:{
    fontWeight:600,
  },
  edit:{
    marginLeft: "6px", fontSize: 16,cursor:'pointer'
  }
}));

export const MapAndSubscriptioCard = (props) => {
  // use classes
  const classes = useStyles(props);
  const history = useHistory();
  //   end
  const viewCustomer = () => {
    history.push({
      pathname: Routes.customersdetails,
      state: {
        isView: true,
        data: props?.customer_id,
      },
    });
  }
  return (
    <>
      {props?.viewbutton && (
        <div className={classes.customerName}>
          <div>
            <Typography className={classes.tittlesubscrition} variant="h6">
              {props?.customerName}
            </Typography>
          </div>
          <div>
            <Button className={classes.drawerButtonTittle} onClick={viewCustomer}>
              View customer Details
            </Button>
          </div>
        </div>
      )}
      <div className={props?.card}>
        {props?.mapSupscription ? (
          <div style={{ width:'100%'}}>

            <Grid container spacing={3} className={classes.mapsubcriptionshow}>
              <Grid
                item
                lg={9}
                md={9}
                sm={9}
                xs={12}
                className={classes.subscriptionparent}
              >
                {/* subscription plan details */}

                <Box className={classes.mainsubscriptionPlan}>

                  <Box className={classes.subscriptionPlan}>

                    <Box style={{ display: "flex", alignItems: "center"}}>

                      <Box >

                        <Typography
                          variant="h6"
                          className={classes.tittlesubscrition}
                          noWrap
                        >
                          {props?.customerName}
                        </Typography>
                      </Box>
                      {props?.isEdit && 
                       <ModeEditIcon
                       className={classes.edit}  onClick={props?.toggleDrawer}
                     />
                      }
                     
                    </Box>

                    <Typography style={{ fontSize: 15, color: "#0000008a" }}>
                      {props?.planname}
                    </Typography>

                  </Box>

                  <Box className={classes.amountpermonth}>
                    <Typography variant="h6" style={{ marginBottom: -10 }}>
                      {props?.amountpermonth}
                    </Typography>
                    <Typography variant="caption">Per {props?.billing_type?.slice(0,-2)}</Typography>
                  </Box>

                </Box>

                <Typography className={classes.subscrptionid}>
                  <span className={classes.subtittle}>Subscription id:</span> &nbsp;{props?.SubscriptionID}
                </Typography>

                <Typography className={classes.subscrptionDetails}>
                <span className={classes.subtittle}>Plan id:</span> &nbsp;{props?.PlanID}
                </Typography>

                <Typography className={classes.subscrptionDetails}>
                <span className={classes.subtittle}>Last billed on</span> &nbsp; {props?.lastbilled} &nbsp;| &nbsp;<span className={classes.subtittle}>Next billing on</span> &nbsp;
                  {props?.nextbilling}
                </Typography>

                {props?.viewbutton && (
                  <Box className={classes.userDate}>

                    <Box className={classes.usernumber}>
                      <PersonIcon />
                      <Typography>6 Users</Typography>
                    </Box>

                    <Box
                      className={classes.usernumber}
                      style={{ marginLeft: "8px" }}
                    >
                      <DateRangeIcon />
                      <Typography>{props?.billing_type}</Typography>
                    </Box>

                  </Box>
                )}
              </Grid>

              {/* total revenue card */}
              <Grid
                item
                lg={3}
                md={3}
                sm={3}
                xs={12}
                className={classes.totalRevenue}
              >

                <Box>
                  <Typography>Total Revenue</Typography>
                  <Typography variant="h4">{props?.totalRevenue}</Typography>
                  <Typography>Active Since: {props?.Active}</Typography>
                </Box>

              </Grid>

              {/* end */}
            </Grid>

            {/* download section */}
            {props?.viewbutton && (

              <div className={classes.downlodsection}>
                <div>
                  <Typography className={classes.tittlesubscrition}
                    style={{ fontSize: 13, textDecoration: 'underline' }}
                  >
                    Click toupgrade / download subscriptionplan
                  </Typography>
                </div>
                <div>
                  <Button className={classes.drawerButtonTittle} onClick={props?.activityfunction}>
                    View ActiveLog
                  </Button>
                </div>
              </div>

            )}

          </div>
        ) : (
          // map subscription button
          <Box>
            <Button
              variant="contained"
              className={classes.drawerButtonTittle}
              onClick={props?.toggleDrawer}
            >
              + Please map subscription
            </Button>
          </Box>
        )}
        {/* drawer map subscription drawer starts */}
      </div>
    </>
  );
};