import React, { useRef, useCallback } from 'react';
import { useStylesManagement } from "./style";
import { Grid, Button, Box, Typography, Avatar } from "@mui/material";
import moment from "moment";


export const AddMemberCard = (props) => {
    const classes = useStylesManagement();

    const observer = useRef();

    const { loading = false, handleInfiniteScroll = null } = props;

    const lastListItemRef = useCallback(
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    handleInfiniteScroll()
                }
            });
            if (node) observer.current.observe(node);
        },
        // eslint-disable-next-line
        [loading]
    );

    return (
        <Grid container spacing={3}>
            {
                props?.data?.map((x, index) => {
                    return (

                        <Grid item xs={6} md={4} lg={4} ref={index === props?.data?.length - 1 ? lastListItemRef : null}>
                            <div className={classes.card} style={{ textAlign: 'center' }}>
                                <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '23px' }}>
                                    <Box className={classes.managericon}>
                                        <img src='/images/manager.svg' alt='manager' />
                                    </Box>
                                    <Box >
                                        <Typography className={classes.roles}>{x?.table_name}</Typography>
                                    </Box>
                                </Box>
                                {
                                    x?.preference?.length > 0 ?
                                        <Box className={classes.selectBox} onClick={() => props?.openDrawer(true, x, "view")}>
                                            <Box>
                                                <Avatar src={"images/editpencil.svg"} className={classes.avatar} onClick={(e) => {
                                                    e.stopPropagation()
                                                    props?.openDrawer(false, x, "edit")
                                                }} />
                                            </Box>
                                            <Box>
                                                {
                                                    x?.preference === 'String and date preferred number' &&
                                                    <Typography className={classes.select}>{x?.prefix}-{x?.date_format?.length > 0 && moment(new Date()).format(x?.date_format)}{x?.date_format?.length > 0 && "-"}{x?.prefix?.length > 0 && x?.prefix} {x?.prefix?.length > 0 && "-"}{x?.starts_with}</Typography>
                                                }
                                                {
                                                    x?.preference === 'Date preferred number' &&
                                                    <Typography className={classes.select}>{x?.date_format?.length > 0 && moment(new Date()).format(x?.date_format)}{x?.date_format?.length > 0 && "-"}{x?.prefix?.length > 0 && x?.prefix} {x?.prefix?.length > 0 && "-"}{x?.starts_with}</Typography>
                                                }
                                                {
                                                    x?.preference === 'String preferred number' &&
                                                    <Typography className={classes.select}>{x?.date_format?.length > 0 && moment(new Date()).format(x?.date_format)}{x?.date_format?.length > 0 && "-"}{x?.prefix?.length > 0 && x?.prefix} {x?.prefix?.length > 0 && "-"}{x?.starts_with}</Typography>
                                                }

                                            </Box>
                                        </Box>
                                        :
                                        <Button variant='outlined' className={classes.addmember} onClick={() => props?.openDrawer(false, x, "create")}>Add Configuration</Button>

                                }




                            </div>
                        </Grid>

                    )
                })
            }
        </Grid>
    )
}