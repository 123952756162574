import React from "react";
import { Grid, Paper, Button, Divider } from "@mui/material";
import { Typography } from "@mui/material";
import { useStylesSettings } from "./style";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MinimizeIcon from '@mui/icons-material/Minimize';

export const PlanSelectCard = (props) => {

    const classes = useStylesSettings(props);

    let noOfPlans = props?.data?.plans?.length;


    // setGridResponse
    const setGridResponse = (key) => {
        if (noOfPlans === 4) {
            let resoForplan4 = {
                planDiv1: "12px",
                planDiv2: "20px",
                featureNameGrid: 4,
                checkCircleGrid: 2
            }
            return resoForplan4[key]
        }
        if (noOfPlans === 3) {
            let resoForplan3 = {
                planDiv1: "30px",
                planDiv2: "40px",
                featureNameGrid: 4.14,
                checkCircleGrid: 2.1
            }
            return resoForplan3[key]
        };
        if (noOfPlans === (2 || 1)) {
            let resoForplan21 = {
                planDiv1: "110px",
                planDiv2: "130px",
                featureNameGrid: 4.14,
                checkCircleGrid: 4
            }
            return resoForplan21[key]
        }

    };


    // check the avaialble feature for plans
    const checkIsFeatureAvailable = (feature, allowed_features) => {
        let availableFeatures = allowed_features?.map((i) => { return i?.features }).flat();
        let x = feature?.feature_id;
        let isAvailable = availableFeatures?.filter((i) => { return i?.feature_id === x });
        return (isAvailable?.length == 0 ? false : true)
    }

    const [expanded, setExpanded] = React.useState(false);


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <Grid container xs={12} sm={12} md={12} className={classes.root}>

            <Grid xs={12} sm={12} md={12} className={classes.paperGrid}>
                <Paper className={classes.paper}>
                    <Grid xs={12} sm={12} md={12} display='flex'>
                        <Grid xs={4} sm={4} md={4}>
                            <div className={classes.headingTypo}>
                                <Typography className={classes.headingTypo1}>{"Check & Choose You Best Subscription"}</Typography>
                                <Typography variant="caption" className={classes.headingTypo2}>{"Here those plans are defined and shown with access levels, And those plans are simply customisable."}</Typography>
                            </div>
                        </Grid>
                        <Grid xs={8} sm={8} md={8}>
                            <div className={classes.planParentDiv}>
                                {props?.data?.plans?.map((i, index) => {
                                    return <div style={{ marginLeft: index === 0 ? setGridResponse("planDiv1") : setGridResponse("planDiv2") }} className={classes.planDiv}>
                                        <Typography className={classes.planName} style={{ color: i?.color ? i?.color : "black" }}>{i?.plan_name}</Typography>
                                        <Typography variant="subtitle2" className={classes.amount}>{"$" + i?.amount + "/Unit"}</Typography>
                                        <Typography variant="caption" className={classes.planDesc}>{i?.description}</Typography>
                                        <Button variant="contained" className={classes.planbtn} onClick={() => { props?.planClick(i) }}>{"contact sales"}</Button>
                                    </div>
                                })}
                            </div>
                        </Grid>
                    </Grid>

                    <Grid xs={12} sm={12} md={12}>
                        <Grid xs={12} sm={12} md={12}>
                            {props?.data?.featureParentData?.map((item) => {
                                return <>
                                    <Accordion expanded={expanded === item?.feature_group_name} onChange={handleChange(item?.feature_group_name)} className={classes.Accordion}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            className={classes.AccordionSummary}
                                        >
                                            <Typography sx={{ width: '33%', flexShrink: 0 }} className={classes.featureGroupName}>
                                                {item?.feature_group_name}
                                            </Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            {item?.features && item?.features?.map((feature) => {
                                                return <Grid xs={12} sm={12} md={12} display="flex" >
                                                    <Grid xs={8} sm={8} md={setGridResponse("featureNameGrid")}>
                                                        <div className={classes.featureNameDiv}>
                                                            <Typography className={classes.featureName}>{feature?.feature_name}</Typography>
                                                            <Divider orientation="vertical" flexItem className={classes.featureNameDivider} />
                                                        </div>
                                                    </Grid>

                                                    {props?.data?.plans?.map((i, PlanIndex) => {
                                                        return <Grid xs={4} sm={4} md={setGridResponse("checkCircleGrid")} display='flex'>
                                                            <div className={classes.checkCircle}>
                                                                {checkIsFeatureAvailable(feature, i?.allowed_features) ? <CheckCircleIcon style={{ color: "#5AC782" }} /> : <MinimizeIcon style={{ color: "#a5b0ba" }} />}
                                                            </div>
                                                            {((props?.data?.plans?.length - 1) !== PlanIndex) && <Divider orientation="vertical" flexItem className={classes.DividerIcon} />}
                                                        </Grid>
                                                    })}

                                                </Grid>
                                            })}
                                        </AccordionDetails>
                                    </Accordion>
                                </>
                            })}
                        </Grid>
                    </Grid>

                </Paper>
            </Grid>

        </Grid >
    );
};
