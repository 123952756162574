import React from "react";
import { Settings } from './settings';
import { withNavBars } from "../../HOCs";

class settingsParent extends React.Component {
  render() {
    return <Settings />;
  }
}

export default withNavBars(settingsParent);