import React from "react";
import { TableComponent, TopNavBar, DrawerComponent } from "../../components";
import { AddontableJson } from "./common";
import { useStylesAddon } from "./style";
import {
  Typography,
  Box,
  Button,
  TextField,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods, Message } from "../../utils";
import { config } from "../../config";
import { BackdropContext, AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import { useTranslation } from "react-i18next";

let IntialState = {
  name: "",
  description: "",
  group: "",
  features: "",
  is_active: false,
  error: {
    name: "",
    description: "",
    group: "",
    features: "",
  },
};



export const Addons = (props) => {
  // use classes
  const classes = useStylesAddon(props);

  const { t } = useTranslation(["topBar"]);

  // end
  const [data, setData] = React.useState({ ...IntialState });

  const [tData, setTdata] = React.useState([]);

  const [tempData, setTempData] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const [dropData, setDropData] = React.useState({
    group: [],
    single: [],
  });

  const [edit, setEdit] = React.useState({
    isEdit: false,
    id: "",
    isView: false
  });
  //   drawer
  const [open, setOpen] = React.useState(false);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);


  const [switchActive, setSwitchActive] = React.useState(false);
  const [searchkey, setSearchkey] = React.useState("");



  const handleFilterSwitchChange = (event) => {
    setSwitchActive(event.target.checked);
  };


  const updateState = async (key, value, label) => {

    if (key === "group") {

      setData({ ...data, group: { "label": label, "value": value }, features: "" });

      let param = {
        feature_group_id: value,
      };

      await NetworkCall(
        `${config.api_rest}api/v1/featuregroup/get/all/features`,
        NetWorkCallMethods.post,
        param,
        null,
        true,
        false
      )
        .then((response) => {
          let main = response.data;
          let arr = [];
          main?.data?.map((key) => {
            let obj = {};
            obj.label = key.name;
            obj.value = key.id;
            arr.push(obj);
          });
          setDropData({ ...dropData, single: arr });
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (key === "features") {

      setData({ ...data, [key]: { "label": label, "value": value } });
    }
    else {
      setData({ ...data, [key]: value });
    }
  };

  const isIamValid = () => {
    let isValid = true;
    let error = data.error;

    if (data.name === "") {
      isValid = false;
      error.name = "Enter Name";
    }

    if (data.description === "") {
      isValid = false;
      error.description = "Enter Description";
    }

    if (data.group === "") {
      isValid = false;
      error.group = "Select Description";
    }

    if (data.features === "") {
      isValid = false;
      error.features = "Select Features";
    }

    setData({ ...data, error });
    return isValid;
  };

  // on Edit
  const onEdit = async (cell, param, e) => {

    if (e) {
      e.stopPropagation();
    }

    // group:
    let FeatureGvalue = dropData.group.filter((i) => { return i?.value === param?.row?.feature_group_id })

    // features:

    let FeatureValue;

    let params = {
      feature_group_id: param?.row?.feature_group_id,
    };

    await NetworkCall(
      `${config.api_rest}api/v1/featuregroup/get/all/features`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        let arr = [];
        main?.data?.map((key) => {
          let obj = {};
          obj.label = key.name;
          obj.value = key.id;
          arr.push(obj);
        });

        FeatureValue = arr?.filter((i) => { return i?.value === param?.row?.feature_id })

      })
      .catch((err) => {
        console.log(err);
      });

    setData({
      name: param?.row?.name,
      description: param?.row?.desc,
      is_active: param?.row?.is_active,
      group: FeatureGvalue[0],
      features: FeatureValue[0]
    });

    setOpen(true);
    setEdit({
      ...edit,
      isEdit: true,
      isView: e ? false : true,
      id: param?.row?.id,
    });
  };


  // Save
  const SaveNew = () => {
    if (isIamValid()) {
      let param;
      {
        edit.isEdit
          ? (param = {
            addon_id: edit?.id,
            name: data.name,
            desc: data.description,
            feature_group_id: data?.group?.value,
            feature_id: data.features?.value,
          })
          : (param = {
            name: data.name,
            desc: data.description,
            feature_group_id: data?.group?.value,
            feature_id: data.features?.value,
          });
      }
      setLoading(true);
      NetworkCall(
        `${config.api_rest}${edit.isEdit ? "api/v1/addon/update" : "api/v1/addon/new"
        }`,
        NetWorkCallMethods.post,
        param,
        null,
        true,
        false
      )
        .then((response) => {
          setLoading(false)
          let main = response.data;
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: main.message,
          });

          setOpen(false);
          getAll();

        })
        .catch((err) => {
          console.log(err);
          setOpen(false);
          setLoading(false)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: Message.addOnErr,
          });
        });
    } else {
      return false;
    }
  };

  const closeDrawer = () => {
    setData({ ...IntialState });
    setOpen(false);
  };

  const openDrawer = () => {
    setData({ ...IntialState });
    setEdit({ ...edit, isEdit: false, isView: false })
    setOpen(true);
  };


  // All Feature
  React.useEffect(() => {
    getAll();
    getFeature();
    // eslint-disable-next-line
  }, []);

  // Get All
  const getAll = (search, filter) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });

    let params = {
      search: search ? search : "",
      filter: filter
    }

    NetworkCall(
      `${config.api_rest}api/v1/addon/get/all`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        setTdata(main?.data);
        if (search?.length === 2) { setTempData(main?.data) }
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get Feature Group
  const getFeature = () => {
    NetworkCall(
      `${config.api_rest}api/v1/featuregroup/get/all/active`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        let arr = [];
        main?.result.map((key) => {
          let obj = {};
          obj.label = key.name;
          obj.value = key.id;
          arr.push(obj);
        });
        setDropData({ ...dropData, group: arr });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRowclick = (param) => {
    onEdit("", param);
  }

  const onAddOnSearch = (val = "") => {

    let search = val?.trim();
    setSearchkey(search)
    if (search?.length === 2) {
      // search throught DB
      getAll(search, switchActive)
    }

    if (search?.length >= 3) {
      // local search 
      let locSearchData = tempData?.filter((item) => {
        if ((item?.id?.toLowerCase()?.indexOf(search?.toLowerCase()) !== -1) || (item?.name?.toLowerCase()?.indexOf(search?.toLowerCase()) !== -1)) {
          return item
        }
      })
      setTdata(locSearchData);
    }

    if (search?.length === 0) {
      getAll(search, switchActive)
    }

  }

  React.useEffect(() => {
    getAll(searchkey, switchActive);
    // eslint-disable-next-line
  }, [switchActive])

  return (
    <div>
      <TopNavBar
        title={t("add_ons")}
        isSearch
        buttonLabel="Create Add-on"
        isFilter
        functions={openDrawer}
        onChangeSearch={onAddOnSearch}
        handleFilterSwitchChange={handleFilterSwitchChange}
        switchActive={switchActive}
      />

      <div className={classes.root}>
        <TableComponent dataList={tData} Header={AddontableJson(onEdit)} cellClick={(rowData) => handleRowclick(rowData)} />
      </div>
      {/* drawer */}
      <DrawerComponent
        DrawerClass={classes.Drawer}
        open={open}
        onClose={closeDrawer}
        component={
          <div className={classes.drawerwidth}>
            <Box className={classes.Header}>
              <Box className={classes.closeIcondrawermap}>
                <Typography variant="h6" onClick={closeDrawer}>
                  <CancelRoundedIcon />
                </Typography>
              </Box>
              <Typography variant="h6" className={classes.title} noWrap>
                Create new add-on
              </Typography>
              <Box>
                {!edit.isView &&
                  <Button
                    variant="contained"
                    style={{ textTransform: "capitalize" }}
                    role="presentation"
                    onClick={SaveNew}
                    className={classes.drawerButtonTittle}
                    disabled={loading}
                  >
                    {loading ? "saving..." : "Save"}
                  </Button>
                }
              </Box>
            </Box>
            <div className={classes.createnewsection}>
              <InputLabel className={classes.label}>
                Plan Name<sup>*</sup>
              </InputLabel>

              <TextField
                className={classes.input}
                fullWidth
                variant="outlined"
                placeholder="Plan Name"
                disabled={edit.isView}
                size="small"
                type="text"
                value={data?.name}
                onChange={(e) => updateState("name", e.target.value)}
              />

              {data?.error?.name !== "" && (
                <Typography style={{ fontSize: "10px", color: "red" }}>
                  {data?.error?.name}
                </Typography>
              )}

              <InputLabel className={classes.label}>
                Description<sup>*</sup>
              </InputLabel>

              <TextField
                className={classes.input}
                placeholder="Description"
                multiline
                fullWidth
                disabled={edit.isView}
                rows={2}
                maxRows={4}
                value={data?.description}
                onChange={(e) => updateState("description", e.target.value)}
              />

              {data?.error?.description !== "" && (
                <Typography style={{ fontSize: "10px", color: "red" }}>
                  {data?.error?.description}
                </Typography>
              )}

              {/* Choose features group */}
              <InputLabel className={classes.label} noWrap>
                Choose features group<sup>*</sup>
              </InputLabel>

              <Autocomplete
                disablePortal
                className={classes.input}
                fullWidth={true}
                variant="outlined"
                value={data?.group}
                disabled={edit.isView}
                options={dropData?.group}
                size="small"
                renderInput={(params) => <TextField {...params} />}
                onChange={(e, newValue) =>
                  updateState("group", newValue.value, newValue.label)
                }
              />

              {data?.error?.group !== "" && (
                <Typography style={{ fontSize: "10px", color: "red" }}>
                  {data?.error?.group}
                </Typography>
              )}

              {/* Choose features */}
              <InputLabel className={classes.label} noWrap>
                Choose features<sup>*</sup>
              </InputLabel>
              <Autocomplete
                className={classes.input}
                fullWidth={true}
                variant="outlined"
                size="small"
                value={data?.features}
                disabled={edit.isView}
                options={dropData?.single}
                renderInput={(params) => <TextField {...params} />}
                onChange={(e, newValue) =>
                  updateState("features", newValue.value, newValue.label)
                }
              />

              {data?.error?.features !== "" && (
                <Typography style={{ fontSize: "10px", color: "red" }}>
                  {data?.error?.features}
                </Typography>
              )}

              {/* check box */}
              {/* <Checkbox color="primary" value={data.Checkbox}/> */}
              <FormControlLabel
                className={classes.label}
                control={
                  <Checkbox
                    checked={data?.is_active}
                    disabled={edit.isView}
                    onChange={(e) => updateState("is_active", !data.is_active)}
                    color="primary"
                    value="Check to activate this features group"
                  />
                }
                label="Check to activate this add-ons"
              />
            </div>
          </div>
        }
      />
    </div>
  );
};
