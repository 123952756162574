import React from "react";
import { TableComponent, TopNavBar } from "../../components";
import { CunstomertableJson } from "./common";
import { useStylesDetails } from "./style";
import { useHistory } from "react-router-dom";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";
import { config } from "../../config";
import { BackdropContext } from "../../contexts";

import { Routes } from "../../router/routes";
import { useTranslation } from "react-i18next";

export const Plan = (props) => {

  // use classes
  const classes = useStylesDetails(props);
  const history = useHistory();
  const [tData, setTdata] = React.useState([]);

  const [tempData, setTempData] = React.useState([]);

  const [switchActive, setSwitchActive] = React.useState(false);
  const [searchkey, setSearchkey] = React.useState("");

  const backdrop = React.useContext(BackdropContext)
  // end

  const { t } = useTranslation(["topBar"]);

  // const data = Array.from(Array(100).keys())?.map((v, i) => {
  //   return {
  //     id: "SPIDER" + i,
  //     status: i % 2 === 0 ? "Active" : "Inactive",
  //     billing: i % 2 === 0 ? "Monthly,Yearly" : "Monthly,Yearly",
  //     public: i % 2 === 0 ? "Yes" : "No",
  //     activity: i % 2 === 0 ? "43" : "53",
  //     modified: i % 2 === 0 ? "Nov 25,2021" : "Nov 25,2021",
  //     Accepted: i % 2 === 0 ? true : false,
  //   };
  // });

  // All Feature
  React.useEffect(() => {
    getAll(searchkey, switchActive);
    // eslint-disable-next-line
  }, [switchActive]);


  // Get All
  const getAll = (search, filter) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });

    let params = {
      search: search ? search : "",
      filter: filter
    }

    NetworkCall(
      `${config.api_rest}api/v1/plan/get/all`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        setTdata(main?.data);
        if (search?.length === 2) { setTempData(main?.data) }
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createPlan = () => {
    history.push({ pathname: Routes.plandetails });
  };

  // on Edit
  const onEdit = (cell, param, e) => {
    e.stopPropagation();
    history.push({
      pathname: Routes.plandetails,
      state: {
        isEdit: true,
        data: param.row,
      },
    });
  };

  const handleRowclick = (param) => {
    history.push({
      pathname: Routes.plandetails,
      state: {
        isView: true,
        isEdit: true,
        data: param.row,
      },
    });
  };

  const handleFilterSwitchChange = (event) => {
    setSwitchActive(event.target.checked);
  };

  const onPlanSearch = (val = "") => {

    let search = val?.trim();
    setSearchkey(search)
    if (search?.length === 2) {
      // search throught DB
      getAll(search, switchActive)
    }

    if (search?.length >= 3) {
      // local search 
      let locSearchData = tempData?.filter((item) => {
        if ((item?.id?.toLowerCase()?.indexOf(search?.toLowerCase()) !== -1) || (item?.name?.toLowerCase()?.indexOf(search?.toLowerCase()) !== -1)) {
          return item
        }
      })
      setTdata(locSearchData);
    }

    if (search?.length === 0) {
      getAll(search, switchActive)
    }

  }

  return (
    <div>

      <TopNavBar
        title={t("plans")}
        isSearch
        buttonLabel="Create Plan"
        isFilter
        functions={createPlan}
        onChangeSearch={onPlanSearch}
        handleFilterSwitchChange={handleFilterSwitchChange}
        switchActive={switchActive}
      />

      <div className={classes.root}>

        <TableComponent
          dataList={tData}
          Header={CunstomertableJson(onEdit)}
          isChecked
          cellClick={(rowData) => handleRowclick(rowData)}
        />

      </div>

    </div>
  );
};
