import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// material UI styles
const useStyles = makeStyles((theme) => ({
      root: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            padding: "10px 10px",
            borderRadius: 4,
            margin: 18
      },
      product: {
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
      },
      plan: {
            color: theme.palette.primary.main,
            backgroundColor: "#fff",
            padding: "2px 4px",
            marginLeft: 10,
            borderRadius: 6,
            fontWeight: "600",
            fontSize: 12
      },
      productdetails: {
            display: "flex",
            justifyContent: "space-between",
            marginTop: 10,
            paddingTop: 4,
            borderTop: "1px solid #ffffff38"
      },
      button: {
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.main,
            textTransform: "capitalize",
            padding: '2px 6px',
            fontSize: 12,
            marginTop: 8,
            fontWeight: 600,
            "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  border: `1px solid ${theme.palette.primary.contrastText}`,
            },
      },
      Arrow: {
            fontSize: "19px",
      },
      f_14: {
            fontSize: "14px",
      },
      update: {
            fontSize: 12,
            color: "#7cfc00c2",
            marginTop: 2,
            display: "flex"
      },
      update_icon: {
            fontSize: 14,
            marginBottom: -2,
      }
}));
// end

export const SwitchBoxParent = (props) => {

      // use classes
      const classes = useStyles({ props });
      // end

      return (
            <div className={classes.root}>

                  {/* title section */}
                  <div className={classes.product}>

                        <Box> Colabo</Box>
                        <Box className={classes.plan}> Dev </Box>

                  </div>
                  {/* end */}

                  {/* email section */}
                  <Typography>dev-pm.colabo.com</Typography>
                  {/* end */}

                  {/* switch button */}
                  <Button variant="outlined" className={classes.button}>

                        Switch to Prod&nbsp;

                        <ArrowForwardIcon className={classes.Arrow} />

                  </Button>
                  {/* end */}

                  {/* product details and status section */}
                  <Box className={classes.productdetails}>

                        <Typography className={classes.f_14}>Product Details</Typography>
                        <Typography className={`${classes.update}`}><CheckCircleOutlineIcon className={classes.update_icon} />&nbsp;Updated</Typography>

                  </Box>
                  {/* end */}
            </div>
      );
};