import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  // TopNavBar,
  SideNavBar
} from "../components";
import Grid from "@mui/material/Grid";

// material UI styles
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "hidden",
  },
  content: {
    // height: "calc(100% - 64px)",
    minHeight: '100vh',
    overflow: "auto",
    width: "100%",
    background: '#f4f4f7',
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  topNavbar: {
    position: "relative",
  },
  sideNavbar: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  positionrelative: {
    position: "relative",
  },
}));
// end

const withNavBars = (Component) => (props) => {

  // use classes
  const classes = useStyles({ props });
  // end

  return (
    <div className={classes.root}>
      {/* Your nav bars here */}

      <Grid container>

        <Grid
          item xs={12} md={3} lg={2.6}
          className={classes.positionrelative}
        >

          {/* sidebar */}
          {
            <div className={classes.sideNavbar}>
              <SideNavBar />
            </div>
          }
        </Grid>
        {/* end */}

        <Grid item xs={12} md={9} lg={9.4}>

          {/* topbar */}

          {/* <div className={classes.topNavbar}>
            <TopNavBar />
          </div> */}

          {/* end */}

          {/* Content */}
          <div className={classes.content}>
            <Component {...props}>{props.children}</Component>
          </div>
          {/* end */}

        </Grid>

      </Grid>

    </div>
  );
};

export default withNavBars;