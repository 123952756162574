import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Button,
  Grid,
  IconButton,
  Checkbox,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { PricingInput } from "../pricinginput";

const useStyles = makeStyles((theme) => ({
  labelTittle: {
    fontSize: "18px",
    fontWeight: 600,
    padding: "10px 15px",
  },
  subTittle: {
    fontSize: "16px",
    fontWeight: 600,
  },

  inputText: {
    fontSize: "16px",
    marginLeft: "10px",
    minWidth: "83px",
  },

  createButton: {
    backgroundColor: theme.palette.blue.main,
    margin: "10px",
    textTransform: "capitalize",
    color: theme.palette.primary.contrastText,
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    padding: "4px 8px",
    '&:hover': {
      backgroundColor: theme.palette.blue.main,
    }
  },
  setPricingMain: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  setPricing: {
    marginTop: "10px",
    "& sup": {
      color: theme.palette.error.main,
    },
    "& .MuiOutlinedInput-root": {
      width: "200px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  },
  pricing: {
    display: "flex",
    marginTop: "10px",
  },
  move: {
    fontSize: 18,
    marginTop: '-4px'
  },
  mobileBottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    [theme.breakpoints.down("md")]: {
      // justifyContent: "space-between",
    },
  }
}));

export const FeaturesCard = (props) => {
  // use classes
  const classes = useStyles(props);
  //   end

  const { setState, state
    // , sample,type
  } = props;

  // console.clear();

 

  return (
    <div className={props?.ActivityCard}>
      {props?.isTittle && (
        <div className={props?.addonTittle}>
          <Typography variant="h6" className={classes.labelTittle}>
            {props?.tittle}
          </Typography>
        </div>
      )}

{/* {
    "feature_id": "777e4784-d925-46f1-b87f-193764864743",
    "feature_name": "feature_name32",
    "control_type": "Limited",
    "threshold": 22,
    "is_active": true
} */}
 
      {state?.map((val, index) => (
        <Grid container className={props?.contantcard}>
          <Grid item lg={4} md={2} sm={12} display="flex" alignItems="center">
          <Checkbox
              color="primary"
              value={val?.feature_id}
              onChange={(e) => props?.setChecked(e.target.checked, val, val?.feature_id, index, props?.groupName, props?.index)}
              checked={val?.is_active}
              disabled={props?.disabled}
              />
            <Typography variant="h6" className={classes.subTittle}>
              {val?.feature_name ? val?.feature_name : val?.feature?.name}
            </Typography>
          </Grid>
          <Grid item lg={8} md={10} sm={12} style={{ width: '100%' }}>
            <Grid container style={{ justifyContent: 'space-between' }}>
              <Grid
                item
                lg={5}
                md={6}
                sm={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >

                {/* ratio section */}
                {val?.is_active && <FormControl>
                  <RadioGroup
                    row
                    name="row-radio-buttons-group"
                  >

                    <FormControlLabel
                      checked={val?.control_type === "Unlimited"}
                      value={"Unlimited"}
                      control={<Radio />}
                      label="Unlimited"
                      disabled={props?.disabled}
                      onClick={(e) =>
                        props.radioChange(
                          setState,
                          state,
                          'control_type',
                          e.target.value,
                          index,
                          val,
                          props?.groupName,
                         props?.index
                        )
                      }
                    />

                    <FormControlLabel
                      checked={val?.control_type === "Limited"}
                      value={"Limited"}
                      control={<Radio />}
                      label="Limited"
                      disabled={props?.disabled}
                      onClick={(e) =>
                        props?.radioChange(
                          setState,
                          state,
                          'control_type',
                          e.target.value,
                          index,
                          val,
                          props?.groupName,
                         props?.index
                        )
                      }
                    />

                  </RadioGroup>

                </FormControl>}
              </Grid>

              {val?.is_active && <Grid
                item
                lg={7}
                md={6}
                sm={6}
                className={classes.mobileBottom}
              >
                {val?.control_type === "Limited" && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      label="threshold"
                      type="number"
                      style={{ width: "150px" }}
                      size="small"
                      className={classes.move}
                      onChange={(e) => props?.inputChange(setState,
                        state, 'threshold', e.target.value, index, val, props?.groupName, props?.index)}
                      value={val?.threshold}
                      disabled={props?.disabled}
                    />
                    <Typography variant="h6" className={classes.inputText}>
                      {val?.inputText}
                    </Typography>
                  </div>
                )}

                {props?.isCancel && <IconButton disabled={props?.disabled} onClick={() => props.DeleteCard(setState, val, index)}>
                <CancelOutlinedIcon/>
                </IconButton>}
               

              </Grid>}

            </Grid>

          </Grid>

          {props?.isPrice && (
            <div className={classes.setPricingMain}>
              <div className={classes.setPricing}>
                <Typography>
                  Set Pricing <sup>*</sup>
                </Typography>
                <Box className={classes.pricing}>
                  <Box>
                    <PricingInput
                      tittle={" Yearly"}
                      type={"number"}
                      placeholder={"Amount..."}
                      onChange={(e) => props?.inputChange(setState,
                        state, 'yearly_price', e.target.value, index, val)}
                      amount={val?.yearly_price ? val?.yearly_price : null}
                      disabled={props?.disabled}
                    />
                  </Box>
                  <Box style={{ marginLeft: "10px" }}>
                    <PricingInput
                      tittle={" Monthly"}
                      type={"number"}
                      placeholder={"Amount..."}
                      onChange={(e) => props?.inputChange(setState,
                        state, 'monthly_price', e.target.value, index, val)}
                        amount={val?.monthly_price ? val?.monthly_price : null}
                        disabled={props?.disabled}
                    />
                  </Box>
                </Box>
              </div>
            </div>
          )}
        </Grid>
      ))}

      {/* add button section */}
      {props?.isButtonNot && (
        <div>
          <Button
            className={classes.createButton}
            onClick={() => props?.addFeatures(props?.type)}
            disabled={props?.disabled}
          >
            {props?.buttontext}
          </Button>
        </div>
      )}
    </div>
  );
};