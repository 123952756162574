import React from "react";
import { Subscription } from './subscription';
import { withNavBars } from "../../HOCs";

class SubscriptionParent extends React.Component {
  render() {
    return <Subscription />;
  }
}

export default withNavBars(SubscriptionParent);