export const Routes = {
  customers: "/customers",
  login:"/",
  dashboard: "/dashboard",
  customersdetails: "/customersdetails",
  subscription:'/subscription',
  subscriptiondetails:'/subscriptiondetails',
  plan:'/plan',
  plandetails:'/plandetails',
  addons:'/addons',
  charges:'/charges',
  features:'/features',
  changePassword:'/changepassword',
  settings:'/settings',
  coupons:'/coupons',
  configuration:'/configuration',
};