import moment from "moment";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const isStatus = (cell, param) => {
  return (
    <span
      style={{
        background: cell ? '#3f51b5' : '#efbb5aeb',
        padding: 4,
        borderRadius: 8,
        color: "#fff",
      }}
    >
      {cell ? "Active" : "inActive"}
    </span>
  );
};

const isPublic = (cell, param) => {
  return (
    <span>
      {cell ? "Yes" : "No"}
    </span>
  );
};

const isdate = (cell, param) => {
  return <span>{moment(cell).format("MMM Do, YYYY")}</span>;
};

export const CunstomertableJson =(onEdit)=> {
  //  table columns tittle
  return{
  TablecolumnData: [
    { field: "id", headerName: "ID", width: 350 },
    { field: "name", headerName: "Plan", width: 120 },
    {
      field: "is_active",
      headerName: "Status",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return isStatus(params.value, params);
      },
    },
    { field: "billing_period", headerName: "Billing", width: 150 },
    { field: "is_public_portal", headerName: "Public", width: 150 ,
    renderCell: (params) => {
      return isPublic(params.value, params);
    },},
    { field: "activity", headerName: "Activity subscriptions", width: 180 },
    { field: "updatedAt", headerName: "Last modified", width: 180 , renderCell: (params) => {
      return isdate(params.value, params);
    },},
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div>
             <ModeEditIcon onClick={(e)=>onEdit(params.value, params, e)} style={{cursor:"pointer",color: "#00000073" }} />
             {/* <DeleteForeverIcon onClick={()=>onDelete(params.value, params)} style={{cursor:"pointer",color: "#00000073"}}/> */}
          </div>
        )
      },
    },
  ],

  //  table dummy datas
  TableData: [],
}
};

export const CollabirationData = {
  feateredata: [
    {
      subTittle: "User",
      isUserInput: true,
      inputText: "User",
      type: "",
      userInput: "",
    },
  ],
  Actiondata: [
    {
      id: "0",
      subTittle: "Action",
      isUserInput: false,
      inputText: "Action",
    },
  ],
  Collabirationdata: [
    {
      id: "0",
      subTittle: "Goals",
      isUserInput: true,
      inputText: "Goals",
    },
    {
      id: "1",
      subTittle: "Messege",
      isUserInput: true,
      inputText: "Messege",
    },
    {
      id: "2",
      subTittle: "Teams",
      isUserInput: true,
      inputText: "Teams",
    },
    {
      id: "3",
      subTittle: "Productivity Board",
      isUserInput: false,
      inputText: "Productivity Board",
    },
    {
      id: "4",
      subTittle: "Stoarge",
      isUserInput: true,
      inputText: "5GB",
    },
  ],
  addonData: [
    {
      // id: "0",
      // subTittle: "Reports1",
      // isUserInput: true,
      // isInput: true,
      // isUserInput: true,
      // actual key
      addons_id:"2a79df07-c532-43b0-ac88-a811a2409ede",
      name:"default",
      control_type: "Limited",
      monthly_price: 2000,
      yearly_price: 24000,
      inputText: "Reports2",
      threshold: 10000
    },
    {
      // id: "1",
      // subTittle: "Customized metrics",
      // isUserInput: false,
      // inputText: "",
      // isInput: true,
       // actual key
       addons_id:"2a79df07-c532-43b0-ac88-a811a2409ede",
       name:"default",
       control_type: "Limited",
       monthly_price: 2000,
       yearly_price: 24000,
       inputText: "Reports2",
       threshold: 10000
    },
  ],
};
