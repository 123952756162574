import React from "react";
import { Addons } from './addon';
import { withNavBars } from "../../HOCs";

class AddonsParent extends React.Component {
  render() {
    return <Addons />;
  }
}

export default withNavBars(AddonsParent);