

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


const isStstus = (cell, param) => {
  return (
    <span
      style={{
        background: cell ? '#3f51b5' : '#efbb5aeb',
        padding: 4,
        borderRadius: 8,
        color: "#fff",
      }}
    >
      {cell ? "Active" : "inActive"}
    </span>
  );
};

// const isStstus = (cell, param, bool) => {
//   return (
//     <span style={{ background: (bool ? colors : color)[param.value], padding: 4, borderRadius: 8, color: '#fff' }}>{cell}</span>
//   );
// };

export const CunstomertableJson =(onEdit)=> {
  //  table columns tittle
  return{
    TablecolumnData: [
      { field: "id", headerName: "Customer id", width: 350 },
      { field: "company_name", headerName: "Customer name", width: 150},
      { field: "business_phone", headerName: "Bussiness phone", width: 150 },
      { field: "company_mail_id", headerName: "Bussiness email", width: 150 },
      {
        field: "is_active",
        headerName: "Current Plan",
        width: 200,
        sortable: false,
        renderCell: (params) => {
          return isStstus(params.value, params);
        },
      },
      {
        field: "Action",
        headerName: "Action",
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <div>
               <ModeEditIcon onClick={(e)=>onEdit(params.value, params,e)} style={{cursor:"pointer",color: "#00000073" }} />
              {/* <DeleteForeverIcon onClick={()=>onDelete(params.value, params)} style={{cursor:"pointer",color: "#00000073"}}/> */}
            </div>
          )
        },
      },
    ],
  
    //  table dummy datas
    TableData: [],
  }

};

// admin panel tabel

export const AdmintableJson =(cellClick)=> {
  //  table columns tittle
  return{
    TablecolumnData: [
      { field: "id", headerName: "ID", width: 350 },
      { field: "name", headerName: "Admin name", width: 150 },
      { field: "mobile", headerName: "Contact number", width: 150 },
      { field: "email_id", headerName: "Email id", width: 250 },
      {
        field: "Action",
        headerName: "Action",
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return <ModeEditIcon style={{ color: "#00000073" }} onClick={()=>cellClick(params.value, params)}/>
        },
      },
    ],
  
    TableData: [],
  }
 
};