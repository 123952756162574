import makeStyles from "@mui/styles/makeStyles";

export const useStylesManagement = makeStyles((theme) => ({
    
      card: {
            border: "2px solid white",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 0px 16px #00000014",
            backgroundColor: "white",
            padding: "16px",
      },

      title: {
            color: '#98A0AC',
            fontSize: "12px",
            fontFamily: "crayond_regular",

      },

      roles: {
            fontSize: '16px',
            fontWeight:"800",
            color: '#091B29',
            fontFamily: "crayond_regular",
            marginBottom: '0 !important',
            marginLeft: '10px'

      },
      managericon: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#E29336',
            justifyContent: 'center',
            padding: '10px 15px'

      },
      sub1: {
            fontSize: "14px",
            color: '#091B29',
            fontFamily: "crayond_regular",
            fontWeight: 600
      },
      select: {
            color: '#091B29',
            fontSize: '12px',
            fontFamily: "crayond_regular",
            marginLeft: '10px'
      },
      selectBox: {
            display: 'flex',
            alignItems: 'center',
            padding: '5px',
            backgroundColor: '#F5F7FA',
            borderRadius: '20px',
            marginTop: '12px',
            height: '40px',
            cursor: 'pointer'
      },
      avatar: {
            height: '30px',
            width: '30px',
            padding: '8px'
      },
      button: {
            width: '100%',
            color: '#fff',
            fontSize: '14px',
            height: '40px',
            fontFamily: "crayond_regular",
            '&:hover': {
                  backgroundColor: '#5078E1',
            }
      },
      preview: {
            color: '#091B29',
            fontSize: '14px',
            fontFamily: "crayond_regular",
            fontWeight:'600'
      },
      previewdetails: {
            color: '#091B29',
            fontSize: '14px',
            fontFamily: "crayond_regular",
      },
      detailsBox: {
            display: 'flex',
            flexFlow: 'wrap !important',
            alignItems: 'center'
      },
      box: {
            padding: '10px'
      }
}));