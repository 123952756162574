//Normal messagess
export let Message = {
  requiredMessage: "This field is required",
  loading: "Loading...",

  // error messages
  featureGroupErr : "Feature group Update unsuccessful !",
  addOnErr : "add-On Update unsuccessful !",
  chargesErr : "charges Update unsuccessful !",
  planErr : "plan Update unsuccessful !"
};
