import React from "react";
import { Coupons } from './coupons';
import { withNavBars } from "../../HOCs";

class couponParent extends React.Component {
  render() {
    return <Coupons />;
  }
}

export default withNavBars(couponParent);