import React from "react";
import { withNavBars } from "../../HOCs";
import { useHistory } from "react-router-dom";
import {
  Grid,
  TextField,
  InputLabel,
  Avatar,
  Tab,
  Box,
  Typography,
  Button,
  Checkbox,
  Badge,
  MenuItem,
  FormControlLabel
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { TopNavBar } from "../../components/navbars/topNavbar/topNavbar";
import { AdmintableJson } from "./common";
import { TableComponent } from "../../components";
import { DrawerComponent } from "../../components/drawer/index";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useLocation } from "react-router-dom";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { PricingDetails, Mobile } from "../../components";
import makeStyles from "@mui/styles/makeStyles";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { NetWorkCallMethods, AlertProps, ValidateEmail, ValidateWebsiteUrl } from "../../utils";
import { AlertContext, BackdropContext } from "../../contexts";
import uuid from 'react-uuid';
import { Routes } from "../../router/routes";
import { TrendingUpTwoTone } from "@mui/icons-material";

// material UI styles
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "19px 12px 18px 19px",
    height: "95vh",
    width: "100%",
    overflow: "auto",
    "& sup": {
      color: "red",
    },
  },
  label: {
    marginTop: "15px",
    textAlign: "left",
    "& sup": {
      color: theme.palette.error.main,
    },
  },
  input: {
    marginBottom: 10,
  },
  label: {
    textAlign: "left",
  },
  customerForm: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: "16px 0px",
    boxShadow: "0px 12px 53px #1d1f7212",
    borderRadius: 6,
  },
  customerInput: {
    padding: "0px 20px",
    marginBottom: "28px",
  },
  tab: {
    marginTop: "28px",
    padding: "10px 20px",
    height: "62px",
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderBottom: "none",
      outline: "none",
      borderRadius: 8,
      minHeight: "40px !important",
      maxHeight: "40px !important",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  tabsTittle: {
    textTransform: "capitalize",
  },
  adminTittle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  createnewsection: {
    padding: "10px 20px",
    height: "100vh",
    overflow: "auto",
    paddingTop: "80px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  drawerlabel: {
    marginTop: "10px",
    "& sup": {
      color: "red",
    },
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "14px",
    borderBottom: "1px solid #00000012",
    position: "absolute",
    width: "100%",
    top: "0",
    height: "77px",
    padding: "15px 24px 20px",
    backgroundColor: theme.palette.primary.contrastText,
    zIndex: "1",
  },
  title: {
    fontWeight: 600,
  },
  logo: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    "& .MuiTextField-root": {
      opacity: "0",
      position: "absolute",
      left: "0",
      zIndex: "1",
      borderRadius: "50%",
      "& .MuiOutlinedInput-input": {
        height: "89px",
      },
    },
  },
  drawerButtonTittle: {
    backgroundColor: "#0f2b79 !important",
    marginLeft: "10px",
    textTransform: "capitalize",
    color: "#fff",
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
  },
  mapSubscription: {
    width: "96%",
    height: "100%",
    minHeight: "200px",
    margin: "12px auto",
    backgroundColor: theme.palette.blue.light,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    color: "#000000cf",
    padding: "20px 24px 20px",
    "& .MuiGrid-item": {
      paddingTop: "0px",
      paddingRight: "11px",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0px",
        paddingLeft: "0px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "30px 11px 30px 34px",
    },
  },
  mapsubdrawerButton: {
    backgroundColor: "#0f2b79 !important",
    marginLeft: "10px",
    textTransform: "capitalize",
    color: "#fff",
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
    marginTop: "-14px",
  },
  menu: {
    color: "red",
  },
  addonSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiCheckbox-root": {
      padding: "5px",
      marginRight: "42px",
    },
  },
  addonSectionTittle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px 10px 0px",
    "& .MuiTypography-root": {
      fontWeight: 600,
      fontSize: "16px",
    },
  },

  Drawer: {
    overflow: "hidden",
    "& .MuiDrawer-paper": {
      overflow: "hidden",
    },
  },
  pricingDetailsMain: {
    marginTop: "20px",
    borderTop: "1px dashed",
    borderColor: theme.palette.primary.main,
  },
  onTrailbutton: {
    border: "2px solid #0f2b79 !important",
    marginLeft: "10px",
    textTransform: "capitalize",
    color: "#0f2b79",
    fontWeight: 600,
    padding: "4px",
    borderRadius: "6px",
    fontSize: 13,
  },
  billingtab: {
    "& .MuiInputLabel-root": {
      marginTop: "2px",
    },
  },
  closeIcondraweradd: {
    right: "520px",
    position: "fixed",
    display: "flex",
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
    fontSize: 28,
    marginTop: 8,
    "& .MuiSvgIcon-root": {
      fontSize: "35px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      right: "11px",
      color: theme.palette.primary.dark,
      top: "0px",
    },
  },
  closeIcondrawermap: {
    right: "520px",
    position: "fixed",
    display: "flex",
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
    fontSize: 28,
    marginTop: 8,
    "& .MuiSvgIcon-root": {
      fontSize: "35px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      right: "11px",
      color: theme.palette.primary.dark,
      top: "0px",
      fontSize: "14px",
    },
  },
  pricingDetailsTittle: {
    fontWeight: 600,
    fontSize: "16px",
  },
  pricingflexBoxTittle: {
    fontSize: "13px",
  },
  drawerwidth: {
    width: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "318px",
    },
  },
}));
// end

const IntialState = {
  company_name: "",
  business_phone: "",
  alternative_phone: "",
  company_mail_id: "",
  company_website: "",
  is_active: false,
  address1: "",
  address2: "",
  landmark: "",
  city: "",
  state: "",
  country: "",
  pincode: "",
  error: {
    company_name: "",
    business_phone: "",
    alternative_phone: "",
    company_mail_id: "",
    company_website: "",
    address1: "",
    address2: "",
    landmark: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  }
}

const InitialState2 = {
  email_id: "",
  mobile: "",
  name: "",
  error: {
    email_id: "",
    mobile: "",
    name: "",
  }
}

const CustomerDetails = (props) => {
  // use classes
  const classes = useStyles(props);
  //   end

  // location state
  const { state } = useLocation();
  //   end
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const history = useHistory();

  // state management
  const [value, setValue] = React.useState("1");
  const [mapsubcription, setmapsubcription] = React.useState(false);
  const [newAdmin, setnewAdmin] = React.useState(false);
  const [mapSupscription, setmapSupscription] = React.useState(false);
  const [values, setValues] = React.useState({ ...IntialState });
  const [admin, setAdmin] = React.useState({ ...InitialState2 });
  const [ids, setIds] = React.useState({
    user_id: "",
    customer_id: "",
  });
  const [tabData, setTabData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [limitNo, setLimitNo] = React.useState('');
  //  end

  //  tab function
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // save next function
  const save = async () => {
    if (state?.isEdit) {
      if (isIamValidValues()) {
        let param1 = {
          customer_id: ids.customer_id,
          customer_detail: values,
        };
        setLoading(true)
        await NetworkCall(
          `${config.api}api/v1/auth/customer/update`,
          NetWorkCallMethods.post,
          param1,
          null,
          true,
          false
        )
          .then((response) => {
            setLoading(false)
            let main = response.data;
            if (response.data) {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: main.message,
              });

              history.push(
                {
                  pathname: Routes.customers
                }
              );
            }
          })
          .catch((err) => {
            setLoading(false)
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: err.message,
            });
          });
      }

    } else {
      if (isIamValidValues()) {
        setLoading(true)
        NetworkCall(
          `${config.api}api/v1/auth/newcustomer`,
          NetWorkCallMethods.post,
          values,
          null,
          true,
          false
        )
          .then((response) => {
            setLoading(false)
            let main = response.data;
            if (main.customer_id) {
              if (tabData.length > 0) {
                let userData = [];
                tabData.map((val) => {
                  let obj = {};
                  obj.name = val.name;
                  obj.email_id = val.email_id;
                  obj.mobile = val.mobile;
                  userData.push(obj);
                  return 0;
                });
                let param = {
                  customer_id: main.customer_id,
                  users: userData.length > 0 ? userData : [],
                };
                setLoading(true)
                NetworkCall(
                  `${config.api}api/v1/auth/signup`,
                  NetWorkCallMethods.post,
                  param,
                  null,
                  true,
                  false
                )
                  .then((response) => {
                    setLoading(false)
                    if (response.data) {
                      alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: `${main.message} and ${response.data.message}`,
                      });

                      history.push(
                        {
                          pathname: Routes.customers
                        }
                      );

                    }
                  })
                  .catch((err) => {
                    setLoading(false)
                    alert.setSnack({
                      ...alert,
                      open: true,
                      severity: AlertProps.severity.error,
                      msg: err.message,
                    });
                  });
              } else {
                setLoading(false)
                alert.setSnack({
                  ...alert,
                  open: true,
                  severity: AlertProps.severity.warning,
                  msg: "Fill the Admin Field",
                });
              }
            }
          })
          .catch((err) => {
            setLoading(false)
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: err.message,
            });
          });
      }

    }

    // history.push("/customers");
  };


  // Get Customers
  const getCustomers = (Id) => {
    NetworkCall(
      `${config.api}api/v1/auth/customer/get`,
      NetWorkCallMethods.post,
      {
        customer_id: Id
      },
      null,
      true,
      false
    )
      .then((response) => {
        let main = response?.data
        setValues({
          company_name: main?.data["company_name"],
          business_phone: main?.data["business_phone"],
          alternative_phone: main?.data["alternative_phone"],
          company_mail_id: main?.data["company_mail_id"],
          company_website: main?.data["company_website"],
          is_active: main?.data["is_active"],
          address1: main?.data["address1"],
          address2: main?.data["address2"],
          landmark: main?.data["landmark"],
          city: main?.data["city"],
          state: main?.data["state"],
          country: main?.data["country"],
          pincode: main?.data["pincode"],
          error: {
            company_name: "",
            business_phone: "",
            alternative_phone: "",
            company_mail_id: "",
            company_website: "",
            address1: "",
            address2: "",
            landmark: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // handle Customer
  const handleValue = (key, val) => {
    setValues({ ...values, [key]: val });
  };
  // handle Admin
  const handleAdmin = (key, val) => {
    if (key === "mobile") {
      setAdmin({ ...admin, [key]: val });
    } else {
      setAdmin({ ...admin, [key]: val });
    }
  };
  // functions
  const toggleDrawer = () => {
    setmapsubcription(!mapsubcription);
  };

  const conform = () => {
    setmapSupscription(true);
    setmapsubcription(false);
  };

  const isIamValidAdmin = () => {
    let isValid = true;
    let error = admin.error;

    if (admin.name === "") {
      isValid = false;
      error.name = "Please Enter Name";
    } else {
      error.name = "";
    }

    if (ValidateEmail(admin.email_id) !== true) {
      isValid = false;
      error.email_id = "Please Enter a valid EmailId";
    } else {
      error.email_id = "";
    }

    if (admin?.id ? false : (admin.mobile?.number?.length !== limitNo)) {
      isValid = false;
      error.mobile = "Please Enter Mobile Number";
    } else {
      error.mobile = ""
    }
    setAdmin({ ...admin, error });
    return isValid;
  };

  const isIamValidValues = () => {
    let isValid = true;
    let error = values.error;

    if (values?.company_name === "") {
      isValid = false;
      error.company_name = "Please Enter company Name";
    } else {
      error.company_name = "";
    }

    if (ValidateEmail(values.company_mail_id) !== true) {
      isValid = false;
      error.company_mail_id = "Please Enter Company mailId";
    } else {
      error.company_mail_id = "";
    }

    if (ValidateWebsiteUrl(values.company_website) !== true) {
      isValid = false;
      error.company_website = "Please Enter valid Company Website url";
    } else {
      error.company_website = "";
    }

    if (values.address1 === "") {
      isValid = false;
      error.address1 = "Please Enter Address1";
    }

    if (values.address2 === "") {
      isValid = false;
      error.address2 = "Please Enter Address2";
    }

    if (values.landmark === "") {
      isValid = false;
      error.landmark = "Please Enter Landmark";
    }

    if (values.city === "") {
      isValid = false;
      error.city = "Please Enter City";
    }
    if (values.state === "") {
      isValid = false;
      error.state = "Please Enter State";
    }
    if (values.country === "") {
      isValid = false;
      error.country = "Please Enter Country";
    }

    if (values.pincode === "") {
      isValid = false;
      error.pincode = "Please Enter Pincode";
    }

    if (values.business_phone?.length !== 10) {
      isValid = false;
      error.business_phone = "Please Enter Buisness Phone (10 digit No)";
    } else {
      error.business_phone = "";
    }

    if (values.alternative_phone?.length !== 10) {
      isValid = false;
      error.alternative_phone = "Please Enter Alternate Phone (10 digit No)";
    } else {
      error.alternative_phone = "";
    }


    setValues({ ...values, error });
    return isValid;
  };

  const newAdmintoggleDrawer = () => {
    if (admin?.id) {

      if (isIamValidAdmin()) {
        let obj = {};
        obj.name = admin?.name;
        obj.email_id = admin?.email_id;
        obj.mobile = admin?.mobile?.phoneCode + " " + admin?.mobile?.number;

        let param2 = {
          user_id: ids.user_id,
          user_detail: obj,
        };

        NetworkCall(
          `${config.api}api/v1/auth/updateuser`,
          NetWorkCallMethods.post,
          param2,
          null,
          true,
          false
        )
          .then((response) => {
            let main = response.data;
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: main.message,
            });
            let param = {
              customer_id: ids.customer_id
            }

            getAllUser(param)
            setnewAdmin(false);

          })
          .catch((err) => {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: err.message,
            });
          });
      }
    }
    else if (isIamValidAdmin()) {

      if (tabData.length > 0) {

        let userData = [
          {
            name: admin?.name,
            mobile: admin?.mobile?.phoneCode + " " + admin?.mobile?.number,
            email_id: admin?.email_id,
          }
        ];

        let param = {
          customer_id: ids.customer_id,
          users: userData.length > 0 ? userData : [],
        };
        setLoading(true)
        NetworkCall(
          `${config.api}api/v1/auth/signup`,
          NetWorkCallMethods.post,
          param,
          null,
          true,
          false
        )
          .then((response) => {
            setLoading(false)
            if (response.data) {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: `${response.data.message}`,
              });
              // construct AdminTable
              let data = [...tabData];
              data.push({
                id: uuid(),
                name: admin?.name,
                mobile: admin?.mobile?.phoneCode + " " + admin?.mobile?.number,
                email_id: admin?.email_id,
              });

              setTabData(data);

              // history.push(
              //   {
              //     pathname: Routes.customers
              //   }
              // );

            }
          })
          .catch((err) => {
            setLoading(false)
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: err.message,
            });
          });
      }

      setnewAdmin(false);
    }
  };

  const toggeleClose = () => {
    setnewAdmin(false);
    setAdmin({ ...InitialState2 })
  };

  const newAddAdmin = () => {
    setAdmin({ ...InitialState2 });
    setLimitNo("");
    setnewAdmin(true);
  };

  // cell click
  const cellClick = (cell, param, bool) => {
    setIds({ ...ids, user_id: param.row.id });
    // construct obj for mobile component
    let mobile = param.row.mobile;
    let mobileArr = mobile.split(" ");
    let mobileObj = { "number": mobileArr[1], "phoneCode": mobileArr[0] }

    setAdmin({
      id: param?.id,
      email_id: param.row.email_id,
      mobile: mobileObj,
      name: param.row.name,
      error: {
        email_id: "",
        mobile: "",
        name: "",
      }
    });
    setnewAdmin(!newAdmin);
  };

  React.useEffect(() => {
    if (state?.isEdit || state?.isView) {
      getCustomers(state.data)
      setIds({ ...ids, customer_id: state.data });
      let param = {
        customer_id: state.data,
      };
      getAllUser(param);
    }
  }, [state]);
  // end

  const getAllUser = (param) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    NetworkCall(
      `${config.api}api/v1/auth/customer/get/all/user`,
      NetWorkCallMethods.post,
      param,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        let data = [];
        main?.rows.map((val, index) => {
          let obj = {};
          obj.id = val.user.id;
          obj.name = val.user.name;
          obj.mobile = val.user.mobile;
          obj.email_id = val.user.email_id;
          data.push(obj);
          return 0;
        });
        setTabData(data);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Topbar bar */}
      <TopNavBar
        title="Create New Customers"
        buttonLabel="Save"
        functions={save}
        back="/customers"
        disabled={loading}
      />
      {/* end */}

      {/* content */}

      <div className={classes.root}>
        <div className={classes.customerForm}>
          <Grid container className={classes.customerInput}>
            <Grid item xs={12} lg={2} md={2} className={classes.logo}>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <ModeEditIcon
                    style={{ color: "gray", marginBottom: -4, marginLeft: -30 }}
                  />
                }
              >
                <TextField type="file"></TextField>
                <Avatar
                  alt="Remy Sharp"
                  src="https://mui.com/static/images/avatar/2.jpg"
                  sx={{ width: 120, height: 120, cursor: "pointer" }}
                />
              </Badge>
            </Grid>
            <Grid item xs={12} lg={10} md={10}>
              <InputLabel className={classes.label}>
                Company Name<sup>*</sup>
              </InputLabel>

              <TextField
                className={classes.input}
                fullWidth
                variant="outlined"
                value={values?.company_name}
                disabled={state?.isView}
                onChange={(e) => handleValue("company_name", e.target.value)}
                placeholder="Company Name..."
                size="small"
                type="text"
              />

              {values?.error?.company_name !== "" && (
                <Typography style={{ fontSize: "10px", color: "red", textAlign: 'left', marginTop: "-6px" }}>
                  {values?.error?.company_name}
                </Typography>
              )}

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel className={classes.label}>
                    Bussiness phone<sup>*</sup>
                  </InputLabel>

                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={values.business_phone}
                    disabled={state?.isView}
                    onChange={(e) =>
                      handleValue("business_phone", e.target.value)
                    }
                    placeholder="Bussiness phone..."
                    className={classes.input}
                    type="number"
                  />
                  {values?.error?.business_phone !== "" && (
                    <Typography style={{ fontSize: "10px", color: "red", textAlign: 'left', marginTop: "-6px" }}>
                      {values?.error?.business_phone}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className={classes.label}>
                    Alternative phone<sup>*</sup>
                  </InputLabel>

                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={values.alternative_phone}
                    disabled={state?.isView}
                    onChange={(e) =>
                      handleValue("alternative_phone", e.target.value)
                    }
                    placeholder="Alternative phone..."
                    className={classes.input}
                    type="number"
                  />
                  {values?.error?.alternative_phone !== "" && (
                    <Typography style={{ fontSize: "10px", color: "red", textAlign: 'left', marginTop: "-6px" }}>
                      {values?.error?.alternative_phone}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel className={classes.label}>
                    Company's email id<sup>*</sup>
                  </InputLabel>

                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={values.company_mail_id}
                    disabled={state?.isView}
                    onChange={(e) =>
                      handleValue("company_mail_id", e.target.value)
                    }
                    placeholder="Company's email id..."
                    className={classes.input}
                    type="email"
                  />
                  {values?.error?.company_mail_id !== "" && (
                    <Typography style={{ fontSize: "10px", color: "red", textAlign: 'left', marginTop: "-6px" }}>
                      {values?.error?.company_mail_id}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className={classes.label}>
                    Company website<sup>*</sup>
                  </InputLabel>

                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={values.company_website}
                    disabled={state?.isView}
                    onChange={(e) =>
                      handleValue("company_website", e.target.value)
                    }
                    placeholder="Company website..."
                    className={classes.input}
                    type="text"
                  />
                  {values?.error?.company_website !== "" && (
                    <Typography style={{ fontSize: "10px", color: "red", textAlign: 'left', marginTop: "-6px" }}>
                      {values?.error?.company_website}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel className={classes.label}
                    control={
                      <Checkbox
                        checked={values?.is_active}
                        disabled={!state?.isEdit}
                        onChange={(e) => handleValue("is_active", !values.is_active)}
                        color="primary"
                        value="Check to activate this features group"
                      />
                    }
                    label="Check to activate this Charges"
                  />
                </Grid>

              </Grid>

              {/* tap contants */}
            </Grid>
          </Grid>

          {/* side navbar appear when user click customers table */}

          {/* {state?.isEdit && (
            <MapAndSubscriptioCard
              mapSupscription={mapSupscription}
              card={classes.mapSubscription}
              classes={classes}
              toggleDrawer={toggleDrawer}
              customerName={"Prasath"}
              amountpermonth={"$175"}
              planname={"Sliver 101+ 3 Addons"}
              SubscriptionID={"893289438"}
              PlanID={"1123481"}
              lastbilled={"22/10/2021"}
              nextbilling={"22/11/2021"}
              totalRevenue={"$2500"}
              Active={"10/02/2019"}
            />
          )} */}

          <DrawerComponent
            DrawerClass={classes.Drawer}
            open={mapsubcription}
            toggleDrawer={toggleDrawer}
            onClose={toggleDrawer}
            component={
              <div>
                <Box className={classes.drawerwidth}>
                  <Box className={classes.Header}>
                    <Box className={classes.closeIcondrawermap}>
                      <Typography variant="h6" onClick={toggleDrawer}>
                        <CancelRoundedIcon />
                      </Typography>
                    </Box>
                    <Typography variant="h6" className={classes.title} noWrap>
                      Map subscription
                    </Typography>
                    <Box>
                      <Button
                        variant="contained"
                        style={{ textTransform: "capitalize" }}
                        role="presentation"
                        onclick={conform}
                        onClick={(toggleDrawer, conform)}
                        onKeyDown={toggleDrawer}
                        className={classes.drawerButtonTittle}
                      >
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <div className={classes.createnewsection}>
                  <InputLabel className={classes.drawerlabel} noWrap>
                    Choose a plan<sup>*</sup>
                  </InputLabel>
                  <TextField
                    select
                    fullWidth={true}
                    variant="outlined"
                    className={classes.menu}
                    size="small"
                  >
                    <MenuItem value={"Gold"}>Gold</MenuItem>
                    <MenuItem value={"Silver"}>Silver</MenuItem>
                  </TextField>
                  <InputLabel className={classes.drawerlabel} noWrap>
                    Choose billing type<sup>*</sup>
                  </InputLabel>
                  <TextField
                    select
                    fullWidth={true}
                    variant="outlined"
                    className={classes.menu}
                    size="small"
                  >
                    <MenuItem value={"Montly"}>Montly</MenuItem>
                    <MenuItem value={"Yearly"}>Yearly</MenuItem>
                  </TextField>

                  <Box className={classes.addonSectionTittle}>
                    <Typography variant="h6">Add-ons</Typography>
                    <Typography>Check to select</Typography>
                  </Box>

                  {["Metrics", "Productivity framework", "Customized"]?.map(
                    (v) => {
                      return (
                        <Box className={classes.addonSection}>
                          <Typography>{v}</Typography>
                          <Checkbox color="primary" />
                        </Box>
                      );
                    }
                  )}

                  {/* Pricing details section */}
                  <div className={classes.pricingDetailsMain}>
                    <PricingDetails
                      plantittle={"Plan"}
                      planename={"Silver"}
                      user={"Per User"}
                      yearly={"Yearly"}
                      planamount={"$244"}
                      addon={"Add ons"}
                      addno={"01"}
                      Report={"Reports"}
                      addamount={"$2"}
                      totalamount={"$246"}
                      cuserDetailshide={true}
                      heading={"Pricing Details"}
                    />
                  </div>
                </div>
              </div>
            }
          />

          {/* tab panels */}

          <TabContext value={value}>
            <Box
              sx={{ border: 1, borderColor: "divider" }}
              className={classes.tab}
            >
              <TabList onChange={handleChange}>
                <Tab
                  label="Billing address"
                  value="1"
                  className={classes.tabsTittle}
                />

                <Tab label="Admin" value="2" className={classes.tabsTittle} />
              </TabList>
            </Box>

            {/* Billing Address tab */}

            <TabPanel value="1">
              <Grid container spacing={2} className={classes.billingtab}>
                <Grid item xs={6}>
                  <InputLabel className={classes.label}>
                    Address line 1<sup>*</sup>
                  </InputLabel>

                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={values.address1}
                    disabled={state?.isView}
                    onChange={(e) => handleValue("address1", e.target.value)}
                    placeholder="Address line 1..."
                    className={classes.input}
                  />

                  {values?.error?.address1 !== "" && (
                    <Typography style={{ fontSize: "10px", color: "red", textAlign: 'left', marginTop: "-6px" }}>
                      {values?.error?.address1}
                    </Typography>
                  )}

                  <InputLabel className={classes.label}>
                    Landmark<sup>*</sup>
                  </InputLabel>

                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={values.landmark}
                    disabled={state?.isView}
                    onChange={(e) => handleValue("landmark", e.target.value)}
                    placeholder="Landmark..."
                    className={classes.input}
                  />
                  {values?.error?.landmark !== "" && (
                    <Typography style={{ fontSize: "10px", color: "red", textAlign: 'left', marginTop: "-6px" }}>
                      {values?.error?.landmark}
                    </Typography>
                  )}
                  <InputLabel className={classes.label}>
                    State<sup>*</sup>
                  </InputLabel>

                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={values.state}
                    disabled={state?.isView}
                    onChange={(e) => handleValue("state", e.target.value)}
                    placeholder="State..."
                    className={classes.input}
                  />

                  {values?.error?.state !== "" && (
                    <Typography style={{ fontSize: "10px", color: "red", textAlign: 'left', marginTop: "-6px" }}>
                      {values?.error?.state}
                    </Typography>
                  )}

                  <InputLabel className={classes.label}>
                    Pincode<sup>*</sup>
                  </InputLabel>

                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={values.pincode}
                    disabled={state?.isView}
                    onChange={(e) => handleValue("pincode", e.target.value)}
                    placeholder="Pincode..."
                    className={classes.input}
                  />

                  {values?.error?.pincode !== "" && (
                    <Typography style={{ fontSize: "10px", color: "red", textAlign: 'left', marginTop: "-6px" }}>
                      {values?.error?.pincode}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <InputLabel className={classes.label}>
                    Address line 2<sup>*</sup>
                  </InputLabel>

                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={values.address2}
                    disabled={state?.isView}
                    onChange={(e) => handleValue("address2", e.target.value)}
                    placeholder="Address line 2..."
                    className={classes.input}
                  />
                  {values?.error?.address2 !== "" && (
                    <Typography style={{ fontSize: "10px", color: "red", textAlign: 'left', marginTop: "-6px" }}>
                      {values?.error?.address2}
                    </Typography>
                  )}

                  <InputLabel className={classes.label}>
                    City<sup>*</sup>
                  </InputLabel>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={values.city}
                    disabled={state?.isView}
                    onChange={(e) => handleValue("city", e.target.value)}
                    placeholder="City..."
                    className={classes.input}
                  />

                  {values?.error?.city !== "" && (
                    <Typography style={{ fontSize: "10px", color: "red", textAlign: 'left', marginTop: "-6px" }}>
                      {values?.error?.city}
                    </Typography>
                  )}

                  <InputLabel className={classes.label}>
                    Country<sup>*</sup>
                  </InputLabel>

                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={values.country}
                    disabled={state?.isView}
                    onChange={(e) => handleValue("country", e.target.value)}
                    placeholder="Country..."
                    className={classes.input}
                  />

                  {values?.error?.country !== "" && (
                    <Typography style={{ fontSize: "10px", color: "red", textAlign: 'left', marginTop: "-6px" }}>
                      {values?.error?.country}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            {/* admin tab */}

            <TabPanel value="2" style={{ paddingTop: "14px" }}>
              <Box className={classes.adminTittle}>
                <Typography>{`Admin(${tabData?.length})`}</Typography>
                {!state?.isView && (
                  <Button
                    variant="contained"
                    className={classes.drawerButtonTittle}
                    onClick={newAddAdmin}
                  >
                    Add
                  </Button>
                )}
              </Box>

              {/* create new admin drawer */}
              <DrawerComponent
                open={newAdmin}
                buttonText={"Add"}
                toggleDrawer={toggeleClose}
                onClose={toggeleClose}
                component={
                  <div className={classes.drawerwidth}>
                    <Box>
                      <Box className={classes.Header}>
                        <Box className={classes.closeIcondraweradd}>
                          <Typography variant="h6" onClick={toggeleClose}>
                            <CancelRoundedIcon />
                          </Typography>
                        </Box>
                        <Typography
                          variant="h6"
                          className={classes.title}
                          noWrap
                        >
                          {admin?.id ? "Edit Admin" : "Create New Admin"}
                        </Typography>
                        <Box>
                          {!state?.isView &&
                            <Button
                              variant="contained"
                              style={{ textTransform: "capitalize" }}
                              onClick={newAdmintoggleDrawer}
                              className={classes.drawerButtonTittle}
                            >
                              Save
                            </Button>
                          }

                        </Box>
                      </Box>
                    </Box>

                    <div className={classes.createnewsection}>
                      <InputLabel className={classes.drawerlabel}>
                        Admin name<sup>*</sup>
                      </InputLabel>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Admin name..."
                        value={admin.name}
                        disabled={state?.isView}
                        onChange={(e) => handleAdmin("name", e.target.value)}
                        className={classes.input}
                        size="small"
                      />
                      {admin?.error?.name !== "" && (
                        <Typography style={{ fontSize: "10px", color: "red", marginTop: "4px" }}>
                          {admin?.error?.name}
                        </Typography>
                      )}
                      <InputLabel className={classes.drawerlabel}>
                        Admin email id<sup>*</sup>
                      </InputLabel>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="email"
                        value={admin.email_id}
                        disabled={state?.isView}
                        placeholder="Admin email id..."
                        onChange={(e) =>
                          handleAdmin("email_id", e.target.value)
                        }
                        className={classes.input}
                        size="small"
                      />
                      {admin?.error?.email_id !== "" && (
                        <Typography style={{ fontSize: "10px", color: "red", marginTop: "2px" }}>
                          {admin?.error?.email_id}
                        </Typography>
                      )}
                      {/* <InputLabel className={classes.drawerlabel}>
                        Contact number<sup>*</sup>
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        type="number"
                        variant="outlined"
                        value={admin.mobile}
                        disabled={state?.isView}
                        placeholder="Contact number..."
                        onChange={(e) => handleAdmin("mobile", e.target.value)}
                        className={classes.input}
                      />
                      {admin?.error?.mobile !== "" && (
                        <Typography style={{ fontSize: "10px", color: "red" }}>
                          {admin?.error?.mobile}
                        </Typography>
                      )} */}
                      <Mobile
                        // id={data?.id ?? index}
                        value={admin.mobile}
                        label={"Mobile No"}
                        handleChange={(value, limit) => handleAdmin("mobile", value, setLimitNo(limit))}
                        type={"number"}
                        // isReadonly={true}
                        isrequired={true}
                        // data={MobNumber}
                        // errorValidation={errorValidation ?? {}}
                        disabled={state?.isView}
                      />
                      {admin?.error?.mobile !== "" && (
                        <Typography style={{ fontSize: "10px", color: "red", marginTop: "4px" }}>
                          {admin?.error?.mobile}
                        </Typography>
                      )}
                    </div>
                  </div>
                }
              />
              {tabData.length > 0 ? (
                <TableComponent
                  dataList={tabData}
                  Header={AdmintableJson(cellClick)}
                  isrowCount
                />
              ) : (
                <Typography>No Data Available</Typography>
              )}
            </TabPanel>
          </TabContext>
        </div>
      </div>

      {/* end */}
    </div >
  );
};
export default withNavBars(CustomerDetails);
