import makeStyles from "@mui/styles/makeStyles";

export const useStylesSettings = makeStyles((theme) => ({
  languageTypo: {
    marginLeft: '20px',
    marginTop: '16px'
  },
  languageSelect: {
    minWidth: "50%",
    marginLeft: '20px',
    marginTop: '6px'
  }
}));
