import React from "react";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "84vh",
    width: "100%",
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
      border: "none",
      cursor: "pointer",
    },
    "&.MuiDataGrid-cell:focus": {
      outline: "none",
      border: "none",
    },
    "&::-webkit-scrollbar": {
      width: "10px",
      height: "0px",
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
  },

  DataGrid: {
    background: "#fff",
    boxShadow: "0px 1px 3px rgb(0 0 0 / 9%)",
    textAlign: "center",
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
    },
    "& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
      justifyContent: "center",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      justifyContent: "center",
    },
  },
}));

export function TableComponent(props) {
  // use classes
  const classes = useStyles(props);
  // end
  const [pageSize, setPageSize] = React.useState(10);

  const { dataList, Header, isChecked, isrowCount, showAction, cellClick } =
    props;

  return (
    <div className={`${classes.DataGrid} ${classes.root}`}>
      <DataGrid
        className={classes.root}
        rows={dataList}
        columns={Header?.TablecolumnData}
        disableColumnMenu
        disableColumnSelector
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        pagination
        hideFooterSelectedRowCount={isrowCount}
        onSelectionModelChange={showAction}
        onRowClick={cellClick}
        ExtendRowFullWidth={true}
      />
    </div>
  );
}
