import React from "react";
import { Charges } from './charges';
import { withNavBars } from "../../HOCs";

class ChargesParent extends React.Component {
  render() {
    return <Charges />;
  }
}

export default withNavBars(ChargesParent);