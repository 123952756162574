import {
  Typography,
  InputLabel,
  TextField,
  Checkbox,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputAdornment,
  Button,
  IconButton,
} from "@mui/material";
import React from "react";
import {
  TopNavBar,
  PricingInput,
  AddFeaturesCard,
  DrawerComponent,
  FeaturesCard
} from "../../components";
import { withNavBars } from "../../HOCs";
import { useStylesplanDetails } from "./style";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { AlertProps } from "../../utils";

import { BackdropContext, AlertContext } from "../../contexts";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods, Message } from "../../utils";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";


import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PlanDetails = (props) => {
  // use classes
  const classes = useStylesplanDetails();

  const backdrop = React.useContext(BackdropContext);

  const history = useHistory();

  const alert = React.useContext(AlertContext);

  const initialState = {
    name: "",
    desc: "",
    is_public_portal: false,
    is_recommended_plan: false,
    is_metered_billing: false,
    is_active: false,
    billing_period: "",
    yearly_fees: "",
    monthly_fees: "",
    user_fee_type: "",
    billing_cycles: "",
    plan_features: [],
    plan_addons: [],
    plan_charges: [],
    error: {
      name: "",
      dec: "",
      billing_period: "",
      yearly_fees: "",
      monthly_fees: "",
      user_fee_type: "",
      billing_cycles: "",
      limited: "",
      chargespricing: "",
    },
  };


  const [ChargesData, setChargesData] = React.useState([]);


  const [addData, setAddData] = React.useState([]);

  const [mapsubcription, setmapsubcription] = React.useState(false);


  // add feature genrel
  const [addFeatureDrawer, SetAddFeatureDrawer] = React.useState(false);
  const [actionCheck, setactionCheck] = React.useState(false);


  const [addChargeDrawer, setAddChargeDrawer] = React.useState(false);
  const [addFeatureGroupDrawer, setAddFeatureGroupDrawer] = React.useState(false);
  const [allChargesData, setAllChargesData] = React.useState([]);

  const [chargesDataIDs, setChargesDataIDs] = React.useState([]);

  const [addDataIDs, setAddDataIDs] = React.useState([]);
  const [addOnDrawer, setAddOnDrawer] = React.useState(false);
  const [addOnData, setAddOnData] = React.useState([]);

  const [isSaving, setIsSaving] = React.useState(false);

  const [dataList, setDataList] = React.useState({ ...initialState });

  const [allFeatureGroupData, setAllFeatureGroupData] = React.useState([]);


  const [constructedDataState, setConstructedData] = React.useState([]);

  const [planFeatureDataForEdit, setPlanFeatureDataForEdit] = React.useState([]);

  // location state
  const { state } = useLocation();

  React.useEffect(() => {
    getAllFeaturesGroups();
    // eslint-disable-next-line
  }, [!state?.isEdit])



  React.useEffect(() => {

    let constructedData = allFeatureGroupData?.map((item) => {
      // debugger
      return {
        feature_group_id: item?.id,
        feature_group_name: item?.name,
        features: item?.feature_group_feature_mappings?.map((item) => {
          return {
            feature_id: item?.feature?.id,
            feature_name: item?.feature?.name,
            control_type: null,
            threshold: null,
            is_active: null,
          }
        })
      }

    })

    setConstructedData([...constructedData])


    if (!state?.isEdit) {
      // debugger
      setDataList({ ...dataList, "plan_features": [...constructedData] })
    }

    // eslint-disable-next-line
  }, [allFeatureGroupData])




  React.useEffect(async () => {

    var dataForBlock;


    if (state?.isEdit && dataList?.name === "") {

      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "",
      });

      let param = {
        plan_id: state?.data?.id
      }

      await NetworkCall(
        `${config.api_rest}api/v1/plan/get`,
        NetWorkCallMethods.post,
        param,
        null,
        true,
        false
      )
        .then((response) => {
          let main = response?.data;
          let data = main?.data;
          dataForBlock = main?.data;

          setDataList({ ...dataList, ...data })

          // set charges temp Arr
          let Cdata = [];
          let CdataID = [];

          data?.plan_charges?.forEach((value) => {
            Cdata.push({ charge_id: value?.charge_id, name: value?.charge?.name ? value?.charge?.name : value?.name, price: value?.price });
            CdataID.push(value?.charge_id);
          })

          setChargesData([...Cdata]);
          setChargesDataIDs([...CdataID]);

          // set addOn temp Arr
          let adondata = [];
          let adondataID = [];

          data?.plan_addons?.forEach((value) => {

            adondata.push({
              addons_id: value?.addons_id,
              name: value?.add_on?.name ? value?.add_on?.name : value?.name,
              control_type: value?.control_type,
              monthly_price: value?.monthly_price,
              yearly_price: value?.yearly_price,
              inputText: value?.add_on?.name ? value?.add_on?.name : value?.name,
              threshold: value?.threshold,
            });

            adondataID.push(value?.addons_id);

          })

          setAddData([...adondata]);
          setAddDataIDs([...adondataID]);

          // set Feature temp Arr
          let FeatureGdata = [];
          let FeatureGdataID = [];


          data?.plan_features?.forEach((value) => {

            FeatureGdata.push(value);

            FeatureGdataID.push(value?.feature_group_id);

          })

          setAddData([...FeatureGdata]);
          setAddDataIDs([...FeatureGdataID]);

          // //////////////////////////////////////////////////////////////////////////////////////////////////







          // //////////////////////////////////////////////////////////////////////////////////////////////////


          // construct JSON for Feature :


          // /////////////////////////////////////////////////////////////////////////////////////////////////

          // backdrop.setBackDrop({
          //   ...backdrop,
          //   open: false,
          //   message: "",
          // });

        })
        .catch((err) => {
          console.log(err);
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
        });



      // backdrop.setBackDrop({
      //   ...backdrop,
      //   open: true,
      //   message: "",
      // });
      await NetworkCall(
        `${config.api_rest}api/v1/featuregroup/get/all`,
        NetWorkCallMethods.post,
        {},
        null,
        true,
        false
      )
        .then(() => {
          // let main = response?.data?.result;

          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
        })
        .catch((err) => {
          console.log(err);
        });

      const ids = dataForBlock?.plan_features?.map((fg) => { return fg?.feature_group_id });

      let uniqIds = ids?.filter((x, i, a) => a?.indexOf(x) === i);

      let modifiedData = uniqIds.map((fgId) => {
        return {
          "feature_group_id": fgId,
          "features": dataForBlock?.plan_features?.filter((e) => { return e?.feature_group_id === fgId })
        }
      });

      let getMasterData = dataForBlock?.plan_features?.map((item) => { return item?.feature_group_id })

      let getModifiData = modifiedData?.map((item) => { return item?.feature_group_id })

      let difference = getModifiData.filter(x => !getMasterData.includes(x));


      let d = [];

      // the previously existed plan from old arr in found and returned //
      let allreadyTheFeatureIsPresent = modifiedData?.map((ind) => { if (difference?.includes(ind?.feature_group_id)) { if (ind !== undefined) { d.push(ind) } } })
      console.log(allreadyTheFeatureIsPresent, "allreadyTheFeatureIsPresent allreadyTheFeatureIsPresent allreadyTheFeatureIsPresent ")
      // constructedData
      constructedDataState?.map((ind, masIndex) => {
        modifiedData.map((dec, modINdex) => {
          if (ind?.feature_group_id === dec?.feature_group_id) {
            ind.features?.map((masterFeature, featureIndex) => {
              if (masterFeature?.feature_id) {
                dec?.features?.map((dsa) => {
                  if (masterFeature?.feature_id === dsa?.feature_id) {
                    constructedDataState[masIndex].features[featureIndex] = dsa
                    // console.log(ind,"indindindinidindind");
                    // console.log(dec,"decdedcdecedcedecedededd");
                    // console.log(dsa,"dsa dsa dsa dsa");
                  }
                })
              }
            })
          }
        })
      })

      // setDataList({ ...dataList, ["plan_features"]: constructedDataState })
      // debugger
      // setDataList({ ...dataList, "plan_features": [...constructedDataState] })
     
      setPlanFeatureDataForEdit(constructedDataState)
      



    }
    // eslint-disable-next-line
  }, [state, constructedDataState]);
  // end



  React.useEffect(() => {
    if (state?.isEdit) {
      // debugger
      setDataList({ ...dataList, "plan_features": [...planFeatureDataForEdit] })
    }
    // eslint-disable-next-line
  }, [planFeatureDataForEdit])









  const setSelectCharges = (e, value, key) => {

    if (e === true) {
      setChargesData([...ChargesData, { charge_id: value?.id, name: value?.name, price: "" }]);
      setChargesDataIDs([...chargesDataIDs, value.id]);
    } else {
      setChargesData(ChargesData.filter((val) => val.charge_id !== value.id));
      setChargesDataIDs(chargesDataIDs.filter((val) => val !== value.id));
    }

  };



  const setSelectAddon = (e, value, key) => {

    if (e === true) {
      setAddData([...addData, {
        addons_id: value?.id,
        name: value?.name,
        control_type: "Limited",
        monthly_price: null,
        yearly_price: null,
        inputText: value?.name,
        threshold: null,
      }]);

      setAddDataIDs([...addDataIDs, value.id]);

    } else {
      setAddData(addData.filter((val) => val.addons_id !== value.id));
      setAddDataIDs(addDataIDs.filter((val) => val !== value.id));
    }
  };

  const saveCharges = () => {
    let ChargesData1 = JSON.parse(JSON.stringify(ChargesData))
    let error = dataList.error;
    error["chargespricing"] = "";
    setDataList({ ...dataList, "plan_charges": [...ChargesData1], error });
    setmapsubcription(false);
  }

  // const saveFeatures = () => {
  //   let error = dataList.error;
  //   error["chargespricing"] = "";
  //   setmapsubcription(false);
  // }

  const saveAddOn = () => {
    let addOnData1 = JSON.parse(JSON.stringify(addData))
    let error = dataList.error;
    error["plan_addons"] = "";
    setDataList({ ...dataList, "plan_addons": [...addOnData1], error });
    setmapsubcription(false);
  }


  const updateState = (key, value) => {
    let error = dataList.error;
    error[key] = "";
    setDataList({ ...dataList, [key]: value, error });
  };


  const setCheckedFeature = (checked, value, val, index, groupName, groupIndex) => {
    // update checked state
    dataList.plan_features[groupIndex].features[index]["is_active"] = checked;
    setDataList({ ...dataList })
  }


  const featureinputChange = (setState, state, key, value, index, val, groupName, groupIndex) => {
    // update checked state
    dataList.plan_features[groupIndex].features[index][key] = value;
    setDataList({ ...dataList })
  }

  const featureRadioChange = (setState, state, key, value, index, val, groupName, groupIndex) => {
    // update radio state
    dataList.plan_features[groupIndex].features[index][key] = value;
    if (value === "Unlimited") {
      dataList.plan_features[groupIndex].features[index]["threshold"] = null;
    }
    setDataList({ ...dataList })
  }

  const addOnRadioChange = (setState, dataUpdate, key, value, index, val) => {
    if (value !== undefined) {

      // update state for payload
      dataList.plan_addons[index][key] = value
      if (value === "Unlimited") {
        dataList.plan_addons[index]["threshold"] = null
      }
      setDataList({ ...dataList })

      // update state for TempArr
      let addOnDataUpdateRadioInTempArr = addData?.find((i) => i?.addons_id === val?.addons_id)
      addOnDataUpdateRadioInTempArr[key] = value
      setAddData([...addData]);

    }
  };

  const inputChange = (setState, dataUpdate, key, value, index, val) => {

    // update state for payload
    dataList.plan_addons[index][key] = value;
    setDataList({ ...dataList });

    // update state for TempArr
    let addOnDataUpdateinTempArr = addData?.find((i) => i?.addons_id === val?.addons_id);
    addOnDataUpdateinTempArr[key] = value;
    setAddData([...addData]);

  };

  const DeleteCard = (setState, dataUpdate, index) => {
    dataList.plan_addons.splice(index, 1);
    setState({ ...dataList })
  };

  // delete addOn 
  const DeleteAddOn = (setState, value, index) => {
    // delete in state for payload
    dataList.plan_addons.splice(index, 1);
    setState({ ...dataList })
    // delete in TempArr
    setAddData(addData.filter((val) => val.addons_id !== value.addons_id));
    setAddDataIDs(addDataIDs.filter((val) => val !== value.addons_id));
  };

  // delete charge 
  const deleteCharges = (setState, value, index) => {
    // delete in state for payload
    dataList.plan_charges.splice(index, 1);
    setState({ ...dataList })
    // delete in TempArr
    setChargesData(ChargesData.filter((val) => val?.charge_id !== value?.charge_id));
    setChargesDataIDs(chargesDataIDs.filter((val) => val !== value?.charge_id));
  };

  // update charge 
  const chargesUpdate = (value, key, val, index) => {
    // update in state for payload
    let priceData = dataList?.plan_charges?.find((i) => i?.charge_id === val?.charge_id);
    priceData.price = value;
    setDataList({ ...dataList })
    // update in TempArr
    let priceDataUpdateinTempArr = ChargesData?.find((i) => i?.charge_id === val?.charge_id);
    priceDataUpdateinTempArr.price = value;
    setChargesData([...ChargesData])
  };

  // const addFeatureGroup = () => {
  //   SetAddFeatureDrawer(false);
  //   setactionCheck(false);
  //   setAddOnDrawer(false);
  //   setAddChargeDrawer(false);
  //   setAddFeatureGroupDrawer(true);
  //   setmapsubcription(!mapsubcription);
  // }

  const addCharges = () => {
    SetAddFeatureDrawer(false);
    setactionCheck(false);
    setAddOnDrawer(false);
    setAddFeatureGroupDrawer(false);
    setAddChargeDrawer(true);
    setmapsubcription(!mapsubcription);
  };


  const closeDrawer = () => {
    setmapsubcription(!mapsubcription);
  };

  // const addFeatures = async (setState, dataUpdate, sample, type) => {

  //   SetAddFeatureDrawer(true);
  //   setactionCheck(false);
  //   setAddChargeDrawer(false);
  //   setAddOnDrawer(false);

  //   setmapsubcription(!mapsubcription);

  // };

  const addAddOn = () => {
    SetAddFeatureDrawer(false);
    setactionCheck(false);
    setAddChargeDrawer(false);
    setAddFeatureGroupDrawer(false);
    setAddOnDrawer(true);
    setmapsubcription(!mapsubcription);
  };


  React.useEffect(() => {

    if (addChargeDrawer === true) {
      getAllCharges();
    }
    if (addOnDrawer === true) {
      getAllAddOn();
    }
    if (addFeatureGroupDrawer === true) {
      getAllFeaturesGroups();
    }
    // eslint-disable-next-line
  }, [addChargeDrawer, addOnDrawer, addFeatureDrawer, addFeatureGroupDrawer])



  // Get All Charges
  const getAllCharges = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    NetworkCall(
      `${config.api_rest}api/v1/charge/get/all`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        setAllChargesData(main?.data);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get All FeaturesGroups
  const getAllFeaturesGroups = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    NetworkCall(
      `${config.api_rest}api/v1/featuregroup/get/all`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let main = response?.data?.result;
        setAllFeatureGroupData(main);

        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get All Addon
  const getAllAddOn = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    NetworkCall(
      `${config.api_rest}api/v1/addon/get/all`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        setAddOnData(main?.data);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getTitleName = () => {
    if (addFeatureDrawer) {
      return "Add Features"
    }
    if (actionCheck) {
      return "add-ons"
    }
    if (addChargeDrawer) {
      return "Add Charges"
    }
    if (addOnDrawer) {
      return "Add Add-on"
    }
    if (addFeatureGroupDrawer) {
      return "Add Feature Groups"
    }
  }


  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  // save next function
  const save = () => {
    if (state?.isEdit) {
      // let param1 = {
      //   customer_id: ids.customer_id,
      //   customer_detail: values,
      // };
      // NetworkCall(
      //   `${config.api}api/v1/auth/customer/update`,
      //   NetWorkCallMethods.post,
      //   param1,
      //   null,
      //   true,
      //   false
      // )
      //   .then((response) => {
      //     let main = response.data;
      //     if (response.data) {
      //       alert.setSnack({
      //         ...alert,
      //         open: true,
      //         severity: AlertProps.severity.success,
      //         msg: main.message,
      //       });
      //       setTimeout(() => {
      //         history.push(
      //           {
      //             pathname:"/customers"
      //           }
      //         );
      //        }, 2500);
      //     }
      //   })
      //   .catch((err) => {
      //     alert.setSnack({
      //       ...alert,
      //       open: true,
      //       severity: AlertProps.severity.error,
      //       msg: err.message,
      //     });
      //   });
    } else {
      // if(isIamValidValues()){
      // debugger
      setIsSaving(true);
      NetworkCall(
        `${config.api_rest}api/v1/plan/new`,
        NetWorkCallMethods.post,
        dataList,
        null,
        true,
        false
      )
        .then(() => {
          // let main = response.data;
          // console.log(main, "main")
          history.push({
            pathname: Routes.plan,
          });
        })
        .catch((err) => {
          setIsSaving(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: Message.planErr,
          });
        });
      // }

    }

    // history.push("/customers");
  };

  return (
    <div>
      <TopNavBar title="Create new plan" buttonLabel={state?.isEdit ? (isSaving ? "Editing..." : "Edit") : (isSaving ? "saving..." : "Save")} functions={save} disabled={isSaving ? isSaving : state?.isView} back="/plan" />

      <div className={classes.root}>
        {/* basic details section */}

        <div className={classes.customerForm}>
          <div className={classes.sections}>
            <Typography className={classes.basicTittle}>
              Basic Details
            </Typography>

            <InputLabel className={classes.label}>
              Plan Name<sup>*</sup>
            </InputLabel>

            <TextField
              className={classes.input}
              fullWidth
              variant="outlined"
              placeholder="Plan Name"
              size="small"
              type="text"
              value={dataList.name}
              onChange={(e) => updateState("name", e.target.value)}
              disabled={state?.isView}
            />

            <InputLabel className={classes.label}>
              Description<sup>*</sup>
            </InputLabel>

            <TextField
              className={classes.input}
              placeholder="Description"
              multiline
              fullWidth
              rows={2}
              maxRows={4}
              value={dataList.desc}
              onChange={(e) => updateState("desc", e.target.value)}
              disabled={state?.isView}
            />

            <div className={classes.checkBoxes}>


              <Box className={classes.checkSection}>
                <Checkbox
                  color="primary"
                  checked={dataList.is_public_portal}
                  onChange={(e) => updateState('is_public_portal', e.target.checked)}
                  disabled={state?.isView}
                />
                <Typography>Check to list this plan in the public portal</Typography>
              </Box>
              <Box className={classes.checkSection}>
                <Checkbox
                  color="primary"
                  checked={dataList.is_recommended_plan}
                  onChange={(e) => updateState('is_recommended_plan', e.target.checked)}
                  disabled={state?.isView}
                />
                <Typography>Check to make this a recommended plan</Typography>
              </Box>
              <Box className={classes.checkSection}>
                <Checkbox
                  color="primary"
                  checked={dataList.is_metered_billing}
                  onChange={(e) => updateState('is_metered_billing', e.target.checked)}
                  disabled={state?.isView}
                />
                <Typography>Check to enable metered billing</Typography>
              </Box>
              <Box className={classes.checkSection}>
                <Checkbox
                  color="primary"
                  checked={dataList.is_active}
                  onChange={(e) => updateState('is_active', e.target.checked)}
                  disabled={state?.isView}
                />
                <Typography>Check to active this plan</Typography>
              </Box>



            </div>
          </div>

          {/* pricing section */}

          <div className={classes.sections}>
            <Typography className={classes.basicTittle}>Pricing</Typography>

            <div className={classes.setPricing}>
              <Typography>
                Billing Pricing <sup>*</sup>
              </Typography>

              <Box className={classes.togglebox} flexGrow={1}>
                <Box
                  className={
                    dataList.billing_period === "Monthly"
                      ? classes.yearlyMonthactive
                      : classes.yearlyMonth
                  }
                  onClick={(e) => updateState("billing_period", "Monthly")}
                  style={{ "pointer-events": state?.isView ? "none" : "auto" }}
                >
                  <Typography>Monthly</Typography>
                </Box>

                <Box
                  className={
                    dataList.billing_period === "Yearly"
                      ? classes.yearlyMonthactive
                      : classes.yearlyMonth
                  }
                  style={{ marginLeft: "10px", "pointer-events": state?.isView ? "none" : "auto" }}
                  onClick={(e) => updateState("billing_period", "Yearly")}
                >
                  <Typography>Yearly</Typography>
                </Box>
              </Box>
            </div>

            {/* set pricing section */}

            <div className={classes.setPricingMain}>
              <div className={classes.setPricing}>
                <Typography>
                  Set Pricing <sup>*</sup>
                </Typography>

                <Box className={classes.pricing}>
                  <PricingInput
                    tittle={" Mountly"}
                    amount={dataList?.monthly_fees}
                    onChange={(e) => updateState("monthly_fees", e.target.value)}
                    isError={dataList?.error?.monthly_fees}
                    errorMessage={"enter valid"}
                    type={"number"}
                    placeholder={"Amount..."}
                    disabled={state?.isView}
                  />
                  &nbsp;
                  <PricingInput
                    tittle={" Yearly"}
                    amount={dataList?.yearly_fees}
                    onChange={(e) => updateState("yearly_fees", e.target.value)}
                    isError={dataList?.error?.yearly_fees}
                    errorMessage={"enter valid"}
                    type={"number"}
                    placeholder={"Amount..."}
                    disabled={state?.isView}
                  />
                </Box>
              </div>

              {/* ratio section */}

              <div>
                <FormControl>
                  <RadioGroup row name="row-radio-buttons-group">
                    <FormControlLabel
                      checked={dataList?.user_fee_type === "per_user"}
                      value={"per_user"}
                      control={<Radio />}
                      label="Per user"
                      onClick={(e) => updateState("user_fee_type", "per_user")}
                      disabled={state?.isView}
                    />

                    <FormControlLabel
                      checked={dataList?.user_fee_type === "flat_fee"}
                      value={"flat_fee"}
                      control={<Radio />}
                      label="Flat fee"
                      onClick={(e) => updateState("user_fee_type", "flat_fee")}
                      disabled={state?.isView}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            {/* Billing cycles */}

            <div className={classes.setPricing}>
              <Typography>
                Billing cycles <sup>*</sup>
              </Typography>

              <Box className={classes.togglebox} flexGrow={1}>
                <Box
                  className={
                    dataList.billing_cycles === "fixed"
                      ? classes.yearlyMonthactive
                      : classes.yearlyMonth
                  }
                  onClick={() => updateState("billing_cycles", "fixed")}
                  style={{ "pointer-events": state?.isView ? "none" : "auto" }}
                >
                  <Typography>Fixed</Typography>
                </Box>

                <Box
                  className={
                    dataList.billing_cycles === "forever"
                      ? classes.yearlyMonthactive
                      : classes.yearlyMonth
                  }
                  style={{ marginLeft: "10px", "pointer-events": state?.isView ? "none" : "auto" }}
                  onClick={() => updateState("billing_cycles", "forever")}
                >
                  <Typography>Forever</Typography>
                </Box>
              </Box>
            </div>
          </div>

          {/* Feature Set section */}
          <div className={classes.sections}>
            <Typography className={classes.basicTittle}>
              Feature Set and Controls
            </Typography>

            <div className={classes.AccordionParentDiv}>

              {dataList?.plan_features?.map((item, index) => {
                return <>
                  <Accordion expanded={expanded === item?.feature_group_name} onChange={handleChange(item?.feature_group_name)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        {item?.feature_group_name}
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <FeaturesCard
                        // tittle={"Collaboration and Productivity"}
                        index={index}
                        groupName={item?.feature_group_name}
                        state={item?.features}
                        limited={() => updateState("collabirationlimited", "limited")}
                        user={() => updateState("collabirationlimited", "unlimited")}
                        ActivityCard={classes.ActivityCard}
                        isButtonNot={false}
                        isCancel={false}
                        contantcard={classes.contantcard}
                        isTittle
                        // addFeatures={addFeatures}
                        radioChange={featureRadioChange}
                        setChecked={setCheckedFeature}
                        inputChange={featureinputChange}
                        // setState={setCollabirationData}
                        DeleteCard={DeleteCard}
                        disabled={state?.isView}
                        sampleJson={{
                          subTittle: "Messege",
                          isUserInput: true,
                          inputText: "Messege",
                        }}
                        buttontext={'+ Add features'}
                      />
                    </AccordionDetails>
                  </Accordion>
                </>
              })}

            </div>

          </div>

          {/* Add-ons section */}

          <div className={classes.sectionsaddon}>
            <Typography className={classes.addonTittle}>Add-ons</Typography>

            {/* set pricing section */}

            <AddFeaturesCard
              state={dataList?.plan_addons}
              isPrice={true}
              limited={() => updateState("limited", "limited")}
              unlimited={() => updateState("limited", "unlimited")}
              limitUser={(e) => updateState("userLimit", e.target.value)}
              disabled={state?.isView}
              isUserInput
              ActivityCard={classes.ActivityCardwithout}
              contantcard={classes.contantcardwithout}
              isTittle
              isButtonNot
              addFeatures={addAddOn}
              radioChange={addOnRadioChange}
              inputChange={inputChange}
              setState={setDataList}
              DeleteCard={DeleteAddOn}
              sampleJson={{
                subTittle: "Reports",
                isUserInput: true,
                inputText: "Reports",
                isInput: true,
              }}
              type="action"
              buttontext={'+ Add add-on'}

            />
          </div>

          {/* charged section */}

          <div className={classes.sectionscharges}>
            <div className={classes.chargesTittlemain}>
              <Typography className={classes.basicTittle}>Charges</Typography>

              <Typography className={classes.ghargeTittle}>
                <ErrorRoundedIcon
                  style={{ fontSize: 17, marginRight: 6, marginBottom: -4 }}
                />
                One - time fee, charged when subscription is actived
              </Typography>
            </div>

            {dataList?.plan_charges?.map((val, index) => {
              return (
                <div>
                  <div className={classes.chargesTittlemain1}>
                    <Typography className={classes.basicTittle}>
                      {val?.charge?.name ? val?.charge?.name : val?.name}
                    </Typography>
                    <Typography className={classes.closeIcon}>
                      <IconButton disabled={state?.isView} onClick={() => deleteCharges(setDataList, val, index)}>
                        <CancelOutlinedIcon />
                      </IconButton>
                    </Typography>
                  </div>

                  {/* set pricing section */}

                  <div className={classes.setPricingMain}>
                    <div className={classes.setPricing}>
                      <Typography>
                        Set Pricing <sup>*</sup>
                      </Typography>
                      <Box className={classes.pricing}>
                        <TextField
                          className={classes.inputPrams}
                          type="number"
                          tittle={" Yearly"}
                          fullWidth
                          value={val?.price}
                          disabled={state?.isView}
                          onChange={(e) =>
                            chargesUpdate(e.target.value, "price", val, index)
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                className={classes.inputPrams}
                                position="end"
                              >
                                $
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                        />
                      </Box>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* Add Charges section */}

            <div>
              {!state?.isView && <Button className={classes.createButton} onClick={addCharges} >
                + Add Charges
              </Button>}
            </div>
          </div>
        </div>
      </div>
      {/* DrawerComponent */}
      <DrawerComponent
        DrawerClass={classes.Drawer}
        open={mapsubcription}
        toggleDrawer={closeDrawer}
        onClose={closeDrawer}
        component={
          <div className={classes.drawerwidth}>
            <Box className={classes.Header}>
              <Box className={classes.closeIcondrawermap}>
                <Typography variant="h6" onClick={closeDrawer}>
                  <CancelRoundedIcon />
                </Typography>
              </Box>
              {<Typography variant="h6" className={classes.title} noWrap>
                {getTitleName()}
              </Typography>}
              <Box>
                {addChargeDrawer && (
                  <>
                    <Button
                      variant="contained"
                      style={{ textTransform: "capitalize" }}
                      role="presentation"
                      onClick={() => saveCharges()}
                      className={
                        allChargesData?.length > 0
                        && classes.drawerButtonTittle
                      }
                      disabled={
                        allChargesData?.length > 0 ? false : true
                      }
                    >
                      Save
                    </Button>
                  </>
                )}
                {addOnDrawer && (
                  <>
                    <Button
                      variant="contained"
                      style={{ textTransform: "capitalize" }}
                      role="presentation"
                      onClick={() => saveAddOn()}
                      className={
                        addOnData?.length > 0
                        && classes.drawerButtonTittle
                      }
                      disabled={
                        addOnData?.length > 0 ? false : true
                      }
                    >
                      Save
                    </Button>
                  </>
                )}
              </Box>
            </Box>
            <div className={classes.createnewsection}>

              {addChargeDrawer && (
                <>
                  {allChargesData?.map((v) => {
                    return (
                      <Box className={classes.addonSection}>
                        <Typography>{v.name}</Typography>
                        <Checkbox
                          color="primary"
                          value={v.id}
                          onChange={(e) => setSelectCharges(e.target.checked, v, v.id)}
                          checked={chargesDataIDs?.some((item) => item === v.id)}
                        />
                      </Box>
                    );
                  })}
                </>
              )}
              {addOnDrawer && (
                <>
                  {addOnData?.map((v) => {
                    return (
                      <Box className={classes.addonSection}>
                        <Typography>{v.name}</Typography>
                        <Checkbox
                          color="primary"
                          value={v.id}
                          onChange={(e) => setSelectAddon(e.target.checked, v, v.id)}
                          checked={addDataIDs?.some((item) => item === v.id)}
                        />
                      </Box>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};
export default withNavBars(PlanDetails);
