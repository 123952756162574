import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, Stack, Divider, Grid } from "@mui/material";

// material UI styles
const useStyles = makeStyles((theme) => ({
  onTrailbutton: {
    border: "2px solid #0f2b79 !important",
    marginLeft: "10px",
    textTransform: "capitalize",
    color: "#0f2b79",
    fontWeight: 600,
    padding: "2px",
    borderRadius: "6px",
    fontSize: 11,
    width: "67px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      marginTop: "10px",
    },
    "& .MuiTypography-root": {
      fontSize: "12px !important",
      textAlign: 'center'
    },
  },
  customerDetails: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor:'pointer',
    "& .MuiTypography-root": {
      fontWeight: "600",
      fontSize: "16px",
    },
  },
  details: {
    margin: "20px 0px",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.mainlight,
    borderRadius: "6px",
    padding: "10px",
  },
  undetails: {
    margin: "20px 0px",
    padding: "10px",
    border: '1px solid' ,
    borderColor:theme.palette.gray.dark,
    backgroundColor: theme.palette.gray.light,
    borderRadius: "6px",
  },
  root: {
    marginTop: "30px",

  },
  customerContact: {
    // marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      display: 'block',
      "& .MuiTypography-root": {
        marginLeft: '0px',
        marginTop: '5px'
      }
    },
    "& .MuiTypography-root": {
      fontWeight: "500",
      fontSize: "13px",
    },

  },
  trailsection: {
    display: "flex",
    justifyContent: "right",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
    },
  },
}));
// end

export const ChooseCustomerBox = (props) => {

  // use classes
  const classes = useStyles({ props });
  // end

  return (
    <div className={classes.root}>

      {/* customer details */}

      {props?.data
        // eslint-disable-next-line
        ?.filter((val) => {
          if (props?.search === "") {
            return val;
          } else if (
            val.customername.toLowerCase().includes(props?.search?.toLowerCase())
          ) {
            return val;
          }
        })
        .map((val, i) => (
          <div
            className={props?.selectedData?.some((item) => item === val.id) ? classes.details : classes.undetails}
            onClick={() => {
              props?.editData(val);
            }}
          >
            <Box className={classes.customerDetails}>

              <Grid container>

                <Grid item xs={12} sm={10} md={10} lg={10}>

                  <Typography>{val?.customername}</Typography>

                  <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                    className={classes.customerContact}
                  >
                    <Typography>{val?.email}</Typography>
                    <Typography>{val?.phonenumber}</Typography>
                  </Stack>
                </Grid>

                <Grid
                  className={classes.trailsection}
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  lg={2}
                >

                  {val?.ontrail && (
                    <div>
                      <Box className={classes.onTrailbutton}>
                        <Typography>onTrail</Typography>
                      </Box>
                    </div>
                  )}

                </Grid>
              </Grid>
            </Box>

          </div>

        ))}

    </div>
  );
};