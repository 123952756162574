import React from "react";
import { withNavBars } from "../../HOCs";
import {
  MapAndSubscriptioCard,
  TableComponent,
  DrawerComponent,
  PricingDetails,
} from "../../components";
import { TopNavBar } from "../../components/navbars/topNavbar/topNavbar";
import { Invoicedata,Billing } from "./common";
import {
  Box,
  Typography,
  Button,
  Avatar,
  InputLabel,
  TextField,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import makeStyles from "@mui/styles/makeStyles";
import { useLocation, useHistory } from "react-router-dom";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import { NetworkCall } from "../../networkcall";

import { NetWorkCallMethods } from "../../utils";

import { config } from "../../config";

import { AlertContext } from "../../contexts";

import { AlertProps } from "../../utils";

import { Routes } from "../../router/routes";

// material UI styles
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "19px 12px 18px 19px",
    height: "95vh",
    width: "100%",
    overflow: "auto",
    "& sup": {
      color: "red",
    },
  },
  customerForm: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: "16px 0px",
    boxShadow: "0px 12px 53px #1d1f7212",
    borderRadius: 6,
  },
  mapSubscription: {
    // width: "96%",
    height: "100%",
    minHeight: "200px",
    margin: 14,
    // margin: "20px auto",
    backgroundColor: theme.palette.blue.light,
    alignItems: "center",
    borderRadius: 10,
    color: theme.palette.common.black,
    padding: "34px 20px 12px",
    "& .MuiGrid-item": {
      paddingTop: "0px",
      paddingRight: "11px",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0px",
        paddingLeft: "0px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "30px 34px 17px",
      margin: 5,
    },
  },
  createnewsection: {
    padding: "10px 20px",
    height: "100vh",
    overflow: "auto",
    paddingTop: "80px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  addonSectionTittle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px 10px 0px",
    "& .MuiTypography-root": {
      fontWeight: 600,
      fontSize: "16px",
    },
  },
  addonSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiCheckbox-root": {
      padding: "5px",
      marginRight: "42px",
    },
  },
  drawerButtonTittle: {
    backgroundColor: "#0f2b79 !important",
    marginLeft: "10px",
    textTransform: "capitalize",
    color: "#fff",
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
  },

  drawerwidth: {
    width: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "318px",
    },
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "14px",
    borderBottom: "1px solid #00000012",
    position: "absolute",
    width: "100%",
    top: "0",
    height: "77px",
    padding: "15px 24px 20px",
    backgroundColor: theme.palette.primary.contrastText,
    zIndex: "1",
  },
  closeIcondrawermap: {
    right: "520px",
    position: "fixed",
    display: "flex",
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
    fontSize: 28,
    marginTop: 8,
    "& .MuiSvgIcon-root": {
      fontSize: "35px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      right: "11px",
      color: theme.palette.primary.dark,
      top: "0px",
      fontSize: "14px",
    },
  },
  createnewsectionlog: {
    padding: "10px 2px",
    height: "100vh",
    overflow: "auto",
    paddingTop: "80px",
    textAlign: "left",
    "& .MuiTimelineItem-root:before": {
      display: "none",
    },
    "& .MuiTimelineDot-root": {
      margin: "0px 0px 15px 0px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  ActivityCard: {
    paddingRight: "10px",
    border: "1px solid",
    borderColor: theme.palette.gray.dark,
    backgroundColor: theme.palette.gray.light,
    borderRadius: "6px",
    alignItems: "center",
    width: "100%",
    marginLeft: "13px",
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  labelTittle: {
    fontSize: "14px",
    fontWeight: 600,
  },
  description: {
    fontSize: "11px",
  },
  time: {
    fontSize: "13px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 14,
    },
  },
  title: {
    fontWeight: 600,
  },
}));
// end

const IntialState = {
  plan_id: "",
  plan_name: "",
  plan_options: {},
  addon_options: {
    Monthly: 0,
    Yearly: 0,
  },
  addons: [],
  billing_type: "",
};

const SubscriptionDetails = (props) => {
  // use classes
  const classes = useStyles(props);
  const history = useHistory();
  //   end
  const [mapsubcription, setmapsubcription] = React.useState(false);
  const [activityDrawer, setactivityDrawer] = React.useState(false);
  const [mainData, setMainData] = React.useState();
  const [jsondata, setJsondata] = React.useState({
    addonOptions: [],
    planOptions: [],
  });
  const [data, setData] = React.useState({ ...IntialState });
  const alert = React.useContext(AlertContext);

  const toggleDrawer = () => {
    setmapsubcription(!mapsubcription);
  };

  const activitytoggleDrawer = () => {
    setactivityDrawer(!activityDrawer);
  };
  // map description drawer
  const [editmapsubcription, seteditmapsubcription] = React.useState(false);

  const edittoggleDrawer = () => {
    seteditmapsubcription(!editmapsubcription);
    getAddons(mainData?.plan_id);
    setData({
      ...data,
      plan_name: mainData?.["plan.name"],
      billing_type: mainData?.billing_type,
      addons: mainData?.addons,
    });
  };

  // Dummy json
  const Tabledata = Array.from(Array(100).keys())?.map((v, i) => {
    return {
      id: "INV8939" + i,
      invoicedate: i % 2 === 0 ? "25-06-2021" : "24-05-2021",
      invoiceamount: i % 2 === 0 ? "$123" : "$124",
      invoicestatus: i % 2 === 0 ? "Overdue" : "Paid",
    };
  });
  // end

  // Activity log Dummy json
  const steps = Array.from(Array(9).keys())?.map((v, i) => {
    return {
      label: "Invoice auto generated",
      description: `By Prakash P (${i * 100000}@gmail.com)`,
      date: `at 0${i + 1}:00 AM`,
      src: "https://mui.com/static/images/avatar/2.jpg",
    };
  });
  // end

  // location state
  const { state } = useLocation();

  React.useEffect(() => {
    getSubscriptions(state?.data);
    getPlan();
    // eslint-disable-next-line
  }, []);

  const updateState = async (key, value, label, option) => {
    if (key === "addons") {
      const first = value;
      let Monthly = option?.Monthly;
      let Yearly = option?.Yearly;

      let amount = {};

      let unchecked = [...data.addons];
      if (data.addons.includes(first)) {
        for (var i = 0; i < unchecked.length; i++) {
          if (unchecked[i] === first) {
            unchecked.splice(i, 1);
          }
        }
        amount.Monthly = data?.addon_options?.Monthly - Monthly;
        amount.Yearly = data?.addon_options?.Yearly - Yearly;
      } else {
        unchecked.push(value);
        amount.Monthly = Monthly + data?.addon_options?.Monthly;
        amount.Yearly = Yearly + data?.addon_options?.Yearly;
      }

      setData({ ...data, addons: unchecked, addon_options: amount });
    } else if (key === "plan_id") {
      getAddons(value);
      setData({
        ...data,
        [key]: value,
        plan_name: label,
        plan_options: option,
        addons: [],
      });
    } else {
      setData({ ...data, [key]: value });
    }
  };

  // Get Customers
  const getSubscriptions = (Id) => {
    NetworkCall(
      `${config.api_rest}api/v1/subscription/get`,
      NetWorkCallMethods.post,
      {
        subscription_id: Id,
      },
      null,
      true,
      false
    )
      .then((response) => {
        let main = response?.data;
        setMainData(main?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get Add-ons
  const getAddons = (Id) => {
    NetworkCall(
      `${config.api_rest}api/v1/plan/get`,
      NetWorkCallMethods.post,
      {
        plan_id: Id,
      },
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        let options = [];
        main?.data?.plan_addons?.map((val) => {
          let obj = {};
          obj.id = val.id;
          obj.name = val?.add_on?.name;
          obj.options = {
            Monthly: val.monthly_price,
            Yearly: val.yearly_price,
          };
          options.push(obj);
        });
        setJsondata({ ...jsondata, addonOptions: options });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // On Update
  const update = () => {
    let NewData = {
      subscription_id: mainData?.id,
      unique_id: localStorage.getItem("profileId"),
      company_id: mainData?.company_id,
      email_id: mainData?.email_id,
      mobile_no: mainData?.mobile_no,
      plan_id: data?.plan_id,
      billing_type: data?.billing_type,
      addons: data?.addons,
      plan_amount:data?.plan_options[data?.billing_type],
      addon_amount:data?.addon_options[data?.billing_type],
      total_amount:isNaN(data?.plan_options[data?.billing_type] + data?.addon_options[data?.billing_type])? 0 : data?.plan_options[data?.billing_type] + data?.addon_options[data?.billing_type]
    };
    if (NewData.plan_id !== "") {
      NetworkCall(
        `${config.api_rest}api/v1/subscription/update`,
        NetWorkCallMethods.post,
        NewData,
        null,
        true,
        false
      )
        .then((response) => {
          history.push({
            pathname: Routes.subscription,
          });
        })
        .finally(() => {
          seteditmapsubcription(false);
        });
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Select any Data",
      });
    }
  };

  //  Get Plans
  const getPlan = () => {
    NetworkCall(
      `${config.api_rest}api/v1/plan/get/all`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data;
        let options = [];
        main?.data?.map((val) => {
          let obj = {};
          obj.value = val.id;
          obj.label = val.name;
          obj.options = {
            Monthly: val.monthly_fees,
            Yearly: val.yearly_fees,
          };
          options.push(obj);
        });
        setJsondata({ ...jsondata, planOptions: options });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  

  return (
    <div>
      {/* Topbar bar */}
      <TopNavBar title={"Subscription Details"} back="/subscription" />
      {/* end */}

      {/* content */}

      <div className={classes.root}>
        <div className={classes.customerForm}>
          {/* customer supscription details  */}
          <MapAndSubscriptioCard
            card={classes.mapSubscription}
            customerName={mainData?.company_name}
            customer_id={mainData?.company_id}
            viewbutton={true}
            amountpermonth={mainData?.["plan.fees"]}
            planname={`${mainData?.["plan.name"]} +${mainData?.addons?.length} Addons`}
            SubscriptionID={mainData?.id}
            PlanID={mainData?.plan_id}
            billing_type={mainData?.billing_type}
            lastbilled={new Date(mainData?.updated_at).toLocaleDateString()}
            nextbilling={"-"}
            totalRevenue={"$2500"}
            Active={new Date(mainData?.subs_start_at).toLocaleDateString()}
            mapSupscription={true}
            activityfunction={activitytoggleDrawer}
            toggleDrawer={edittoggleDrawer}
            isEdit={state?.isEdit}
          />
        </div>
        {/* invoices tabel  */}
        <TableComponent
          dataList={Tabledata}
          Header={Invoicedata}
          cellClick={toggleDrawer}
        />
        {/* edit map description drawer */}
        <DrawerComponent
          DrawerClass={classes.Drawer}
          open={editmapsubcription}
          toggleDrawer={edittoggleDrawer}
          onClose={edittoggleDrawer}
          component={
            <div>
              <Box className={classes.drawerwidth}>
                <Box className={classes.Header}>
                  <Box className={classes.closeIcondrawermap}>
                    <Typography variant="h6" onClick={edittoggleDrawer}>
                      <CancelRoundedIcon />
                    </Typography>
                  </Box>
                  <Typography variant="h6" className={classes.title} noWrap>
                    Map subscription
                  </Typography>
                  <Box>
                    <Button
                      variant="contained"
                      style={{ textTransform: "capitalize" }}
                      role="presentation"
                      onClick={update}
                      onKeyDown={edittoggleDrawer}
                      className={classes.drawerButtonTittle}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Box>
              </Box>
              <div className={classes.createnewsection}>
                <InputLabel className={classes.drawerlabel} noWrap>
                  Choose a plan<sup>*</sup>
                </InputLabel>

                <Autocomplete
                  className={classes.menu}
                  fullWidth={true}
                  variant="outlined"
                  size="small"
                  value={data?.plan_name}
                  options={jsondata?.planOptions}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(e, newValue) =>
                    updateState(
                      "plan_id",
                      newValue.value,
                      newValue.label,
                      newValue.options
                    )
                  }
                />

                <InputLabel className={classes.drawerlabel} noWrap>
                  Choose billing type<sup>*</sup>
                </InputLabel>

                <Autocomplete
                  className={classes.menu}
                  fullWidth={true}
                  variant="outlined"
                  size="small"
                  value={data?.billing_type}
                  options={Billing}
                  onChange={(e, newValue) =>
                    updateState("billing_type", newValue.label)
                  }
                  renderInput={(params) => <TextField {...params} />}
                />

                <Box className={classes.addonSectionTittle}>
                  <Typography variant="h6">Add-ons</Typography>
                  <Typography>Check to select</Typography>
                </Box>

                {jsondata?.addonOptions?.map((v, i) => {
                  return (
                    <Box className={classes.addonSection}>
                      <Typography>{v.name}</Typography>
                      <Checkbox
                        color="primary"
                        checked={data?.addons?.some((x) => x === v.id)}
                        onChange={(e) =>
                          updateState("addons", v.id, null, v.options)
                        }
                      />
                    </Box>
                  );
                })}

                {/* Pricing details section */}

                <div className={classes.pricingDetailsMain}>
                  <PricingDetails
                    plantittle={"Plan"}
                    planename={mainData?.["plan.name"]}
                    user={"Per User"}
                    yearly={mainData?.billing_type}
                    planamount={mainData?.plan_amount ? mainData.plan_amount : 0}
                    addon={"Add-ons"}
                    addno={mainData?.addons?.length}
                    Report={"Reports"}
                    addamount={mainData?.addon_amount ? mainData.addon_amount : 0}
                    totalamount={mainData?.total_amount ? mainData.total_amount : 0}
                    cuserDetailshide={true}
                    heading={"Previous Pricing Details"}
                    customer_name={mainData?.company_name}
                    customer_id={mainData?.company_id}
                    customer_email={mainData?.email_id}
                    customer_number={mainData?.mobile_no}
                  />

                  <PricingDetails
                    plantittle={"Plan"}
                    planename={data?.plan_name}
                    user={"Per User"}
                    yearly={data?.billing_type}
                    planamount={data?.plan_options[data?.billing_type]}
                    addon={"Add-ons"}
                    addno={data?.addons?.length}
                    Report={"Reports"}
                    addamount={data?.addon_options[data?.billing_type]}
                    totalamount={
                      isNaN(
                        data?.plan_options[data?.billing_type] +
                          data?.addon_options[data?.billing_type]
                      )
                        ? 0
                        : data?.plan_options[data?.billing_type] +
                          data?.addon_options[data?.billing_type]
                    }
                    cuserDetailshide={false}
                    heading={"New Pricing Details"}
                  />
                </div>
              </div>
            </div>
          }
        />{" "}
        {/* invoice download drawer */}
        <DrawerComponent
          DrawerClass={classes.Drawer}
          open={mapsubcription}
          toggleDrawer={toggleDrawer}
          onClose={toggleDrawer}
          component={
            <div className={classes.drawerwidth}>
              <Box className={classes.Header}>
                <Box className={classes.closeIcondrawermap}>
                  <Typography variant="h6" onClick={toggleDrawer}>
                    <CancelRoundedIcon />
                  </Typography>
                </Box>
                <Typography variant="h6" className={classes.title} noWrap>
                  INV89390
                </Typography>
                <Box>
                  <Button
                    variant="contained"
                    style={{ textTransform: "capitalize" }}
                    role="presentation"
                    onclick={toggleDrawer}
                    onClick={toggleDrawer}
                    onKeyDown={toggleDrawer}
                    className={classes.drawerButtonTittle}
                  >
                    Download
                  </Button>
                </Box>
              </Box>
            </div>
          }
        />
        {/* activity log drawer */}
        <DrawerComponent
          DrawerClass={classes.Drawer}
          open={activityDrawer}
          toggleDrawer={activitytoggleDrawer}
          onClose={activitytoggleDrawer}
          component={
            <div className={classes.drawerwidth}>
              <Box className={classes.Header}>
                <Box className={classes.closeIcondrawermap}>
                  <Typography variant="h6" onClick={activitytoggleDrawer}>
                    <CancelRoundedIcon />
                  </Typography>
                </Box>

                <Typography variant="h6" className={classes.title} noWrap>
                  Activity log
                </Typography>
              </Box>

              <div className={classes.createnewsectionlog}>
                {steps?.map((val, index) => (
                  <Timeline position="right">
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot>
                          <Avatar
                            alt="Remy Sharp"
                            src="https://mui.com/static/images/avatar/2.jpg"
                            sx={{ width: 30, height: 30 }}
                          />
                        </TimelineDot>
                        <TimelineConnector style={{ marginBottom: "-18px" }} />
                      </TimelineSeparator>

                      <div className={classes.ActivityCard}>
                        <TimelineContent>
                          <Typography
                            variant="h6"
                            component="span"
                            className={classes.labelTittle}
                          >
                            {val?.label}
                          </Typography>

                          <Typography className={classes.description} noWrap>
                            {val?.description}
                          </Typography>
                        </TimelineContent>

                        <Box>
                          <Typography className={classes.time} noWrap>
                            {val?.date}
                          </Typography>
                        </Box>
                      </div>
                    </TimelineItem>
                  </Timeline>
                ))}
              </div>
            </div>
          }
        />
      </div>

      {/* end */}
    </div>
  );
};
export default withNavBars(SubscriptionDetails);
