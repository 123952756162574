import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  Label: {
    color: "black",
    fontFamily: "tenant_semibold",
    fontSize: "14px",
    marginBottom: "5px",
  },
  text: {},

  textbox: {
    backgroundColor: (props) => props?.color ?? "auto",
    borderRadius: theme.palette.borderRadius,
    border: "none",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      backgroundColor: '#fff'
    },
    [`& fieldset`]: {
      borderRadius: theme.palette.borderRadius,
      height: (props) => (props.multiline ? "unset" : props?.height ?? 50),
      border: (props) => props?.border ?? "1px solid #CED3DD",
      "&:hover": {
        border: "1px solid red",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => props?.padding ?? "11px 14px",
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#5078E1',
      },

    },

  },

}));